import * as React from "react";

import { QueryClient, QueryClientProvider } from "react-query";
import { createBrowserRouter, RouterProvider } from "react-router-dom";

import { MainLayout } from "./MainLayout";
import { Home } from "./Home";
import { SignIn } from "./SignIn";
import { Register } from "./Register";
import { Forgotten } from "./Forgotten";
import { NewForm } from "./Form/generate";
import { TestCase } from "./Form/TestCase";
import { SuiteDefaultRedirect } from "./Suite";
import { TestCaseDetail } from "./Suite/TestCaseDetail";
import { FeatureList } from "./Suite/Feature";
import { Admin } from "./Admin";
import { Add as AddResult } from "./Results/Add";
import { List as ListResults } from "./Results/List";
import { Pricing } from "./Pricing";
import { PlanList } from "./Plan/List";
import { PlanDetail } from "./Plan/Details";
import { TestCaseGenerator } from "./TestCaseGenerator";

const queryClient = new QueryClient({
  defaultOptions: {
    // https://tanstack.com/query/v4/docs/reference/useQuery
    queries: {
      retry: 1,
      refetchOnMount: false,
      staleTime: Infinity,
      refetchOnWindowFocus: false,
    },
    mutations: {},
  },
});

const router = createBrowserRouter([
  {
    path: "/",
    element: <Home />,
    index: true,
  },
  {
    path: "/forgotten",
    element: <Forgotten />,
  },
  {
    path: "/register",
    element: <Register />,
  },
  {
    path: "/signin",
    element: <SignIn />,
  },
  {
    path: "/app",
    element: <MainLayout />,
    children: [
      {
        path: "test-case-generator",
        element: <TestCaseGenerator />,
      },
      {
        path: "premium",
        element: <Pricing />,
      },
      {
        path: "new",
        element: <NewForm />,
      },
      {
        path: ":suiteId",
        element: <SuiteDefaultRedirect />,
      },
      {
        path: ":suiteId/tc",
        element: <TestCase />,
      },
      {
        path: ":suiteId/tc/:tcId",
        element: <TestCaseDetail />,
      },
      {
        path: ":suiteId/plan",
        element: <PlanList />,
      },
      {
        path: ":suiteId/plan/:planId",
        element: <PlanDetail />,
      },
      {
        path: ":suiteId/feat",
        element: <FeatureList />,
      },
      {
        path: ":suiteId/admin",
        element: <Admin />,
      },
      {
        path: ":suiteId/results",
        element: <ListResults />,
      },
      {
        path: ":suiteId/results/add",
        element: <AddResult />,
      },
    ],
  },
]);

export const App = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <RouterProvider router={router} />
    </QueryClientProvider>
  );
};
