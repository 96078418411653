import * as React from "react";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { HamburgerIcon } from "@chakra-ui/icons";
import {
  Flex,
  FormControl,
  FormLabel,
  Text,
  Box,
  Menu,
  MenuItem,
  MenuButton,
  MenuList,
  Input,
  IconButton,
  Center,
  useDisclosure,
} from "@chakra-ui/react";
import { CreatableSelect, ChakraStylesConfig } from "chakra-react-select";
import { RBP } from "../RBP";
import { AddResultModal } from "../Results/AddModal";

const featureSelectStyles: ChakraStylesConfig = {
  dropdownIndicator: (baseStyles) => ({
    ...baseStyles,
    background: "brand.bg",
  }),
  option: (baseStyles) => ({
    ...baseStyles,
    background: "brand.bg",
    _hover: {
      color: "brand.primary",
    },
  }),
  menuList: (baseStyles) => ({
    ...baseStyles,
    background: "brand.bg",
  }),
};

const cellWidth = ["5%", "45%", "40%", "10%"];

const ResultText = ({ tcLastResult }: any) => {
  return (
    <>
      {tcLastResult.passed ? "Passed" : "Failed"} @{" "}
      {new Date(tcLastResult.date).toLocaleString()}
    </>
  );
};

export const CaseRow = ({
  deleteCase,
  addCaseText,
  caseDetails,
  featureData,
  addFeat,
  linkFeat,
  tcLastResult,
  suiteId,
  flags,
  action1Fn,
  action1Label,
}: any) => {
  const navigate = useNavigate();
  const [selectedOption, setSelectedOption] = useState([]);
  const [availOptions, setAvailOptions] = useState([]);
  const [name, setName] = useState(caseDetails.name);
  const disclosure = useDisclosure();

  const propagateName = () => {
    addCaseText(caseDetails.id, name);
  };

  const optionChanged = (x: any) => {
    const newFeatSet = [...new Set(x.map((y: any) => y.value))];
    linkFeat(caseDetails.id, newFeatSet);
  };

  const onFeatCreate = async (x: any) => {
    const created = await addFeat(x);
    const found = created.find((y: any) => y.name === x);
    if (found) {
      const newFeatSet = [...(caseDetails?.features ?? []), found?.id];
      linkFeat(caseDetails.id, newFeatSet);
    }
  };

  useEffect(() => {
    setSelectedOption(
      (caseDetails.features ?? []).map((f: string) => ({
        label: featureData.find((x: any) => x.id === f)?.name,
        value: featureData.find((x: any) => x.id === f)?.id,
      }))
    );
  }, [caseDetails, featureData]);

  useEffect(() => {
    setAvailOptions(
      (featureData ?? []).map((f: any) => ({
        label: f.name,
        value: f.id,
      }))
    );
  }, [featureData]);

  const nameWidth = flags.includes("feature") ? cellWidth[1] : "85%";

  const lastResultColor = (lastResult: any) =>
    lastResult?.passed ? "brand.success.main" : "brand.warning.main";

  return (
    <Flex direction="row" _hover={{ bg: "gray.50" }} pb="2">
      <Box width={cellWidth[0]} pt="3">
        <Center>
          <Text>
            {caseDetails.id.startsWith("tc_") &&
              caseDetails.id.replace("tc_", "")}
          </Text>
        </Center>
      </Box>
      <Box width={nameWidth} padding="1">
        <RBP admin collab>
          {flags.includes("editname") ? (
            <FormControl variant="floating" id="first-name">
              <Input
                onBlur={propagateName}
                onChange={(e) => setName(e.target.value)}
                value={name}
                id={caseDetails.id}
                tabIndex={caseDetails.number * 10 + 1}
              />
              {tcLastResult && (
                <FormLabel color={lastResultColor(tcLastResult)}>
                  <ResultText tcLastResult={tcLastResult} />
                </FormLabel>
              )}
            </FormControl>
          ) : (
            <>
              <Text pt="2">{caseDetails.name}</Text>
              {tcLastResult && (
                <Text
                  textAlign="right"
                  fontSize="small"
                  color={lastResultColor(tcLastResult)}
                >
                  <ResultText tcLastResult={tcLastResult} />
                </Text>
              )}
            </>
          )}
        </RBP>
        <RBP executor view>
          <Text pt="2">{caseDetails.name}</Text>
        </RBP>
      </Box>

      {flags.includes("feature") && (
        <Box width={cellWidth[2]} padding="1">
          <RBP admin collab>
            <CreatableSelect
              isMulti
              value={selectedOption}
              options={availOptions}
              onChange={optionChanged}
              onCreateOption={onFeatCreate}
              isClearable={false}
              placeholder="Select or start typing to add"
              chakraStyles={featureSelectStyles}
              tabIndex={caseDetails.number * 10 + 2}
            />
          </RBP>
          <RBP executor view>
            <Text pt="2">
              {selectedOption.map((x: any) => x.label).join(", ")}
            </Text>
          </RBP>
        </Box>
      )}
      <Box width={cellWidth[3]} padding="1">
        <Center>
          <Menu>
            <MenuButton
              bg="brand.bg"
              border="1px"
              borderColor="gray.100"
              as={IconButton}
              icon={<HamburgerIcon />}
              _hover={{
                bg: "brand.bg",
              }}
              _active={{
                bg: "brand.bg",
                transform: "scale(0.98)",
                color: "brand.text.primary",
              }}
              tabIndex={caseDetails.number * 10 + 3}
            ></MenuButton>
            <MenuList bg="brand.bg">
              {action1Label && (
                <OptionMenuItem onClick={() => action1Fn(caseDetails.id)}>
                  {action1Label}
                </OptionMenuItem>
              )}
              {flags.includes("expectations") && (
                <RBP admin collab>
                  <OptionMenuItem
                    onClick={() =>
                      navigate(`/app/${suiteId}/tc/${caseDetails.id}`)
                    }
                  >
                    Add Expectations
                  </OptionMenuItem>
                </RBP>
              )}
              {flags.includes("results") && (
                <>
                  <RBP admin collab executor>
                    <OptionMenuItem onClick={() => disclosure.onOpen()}>
                      Record Result
                    </OptionMenuItem>
                  </RBP>
                  <RBP admin collab executor view>
                    <OptionMenuItem
                      onClick={() =>
                        navigate(`/app/${suiteId}/results?tc=${caseDetails.id}`)
                      }
                    >
                      View Results
                    </OptionMenuItem>
                  </RBP>
                </>
              )}
              {flags.includes("delete") && (
                <RBP admin collab>
                  <OptionMenuItem onClick={() => deleteCase(caseDetails.id)}>
                    Delete Case
                  </OptionMenuItem>
                </RBP>
              )}
            </MenuList>
          </Menu>
        </Center>
      </Box>
      <AddResultModal disclosure={disclosure} tcId={caseDetails.id} />
    </Flex>
  );
};

const OptionMenuItem = (props: any) => {
  return (
    <MenuItem
      bg="brand.bg"
      color="brand.text.primary"
      _focus={{
        bg: "brand.bg",
        color: "brand.primary.main",
      }}
      _hover={{
        bg: "brand.bg",
        color: "brand.primary.main",
      }}
      _active={{
        bg: "brand.bg",
        transform: "scale(0.98)",
        color: "brand.primary.main",
      }}
      {...props}
    ></MenuItem>
  );
};
