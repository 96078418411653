import {
  CognitoUserPool,
  AuthenticationDetails,
  CognitoUser,
  CognitoUserSession,
} from "amazon-cognito-identity-js";

import { poolData } from "./config";

type SigninVars = {
  username: string;
  password: string;
};

export const signin = async ({ username, password }: SigninVars) => {
  const userPool = new CognitoUserPool(poolData);

  const authenticationData = {
    Username: username,
    Password: password,
  };

  const authenticationDetails = new AuthenticationDetails(authenticationData);
  const userData = {
    Username: username,
    Pool: userPool,
  };

  return new Promise((resolve, reject) => {
    const cognitoUser = new CognitoUser(userData);
    cognitoUser.authenticateUser(authenticationDetails, {
      onSuccess: function (result) {
        const accessToken = result.getAccessToken().getJwtToken();
        const idToken = result.getIdToken().getJwtToken();

        const output = {
          accessToken,
          idToken,
        };

        return resolve(output);
      },
      onFailure: (err) => {
        reject(err);
      },
    });
  });
};

type IdToken = {
  email: string;
  sub: string;
};

export const getUser = async (): Promise<false | IdToken> => {
  const userPool = new CognitoUserPool(poolData);
  const cognitoUser = userPool.getCurrentUser();

  if (cognitoUser === null) return false;

  return new Promise((resolve, reject) => {
    cognitoUser.getSession((err: Error | null, session: CognitoUserSession) => {
      if (err) {
        return reject(err);
      }

      const idToken = session.getIdToken().payload;
      return resolve(<IdToken>idToken);
    });
  });
};

export const getAccessToken = async () => {
  const userPool = new CognitoUserPool(poolData);
  const cognitoUser = userPool.getCurrentUser();

  if (cognitoUser === null) return null;

  return new Promise((resolve, reject) => {
    cognitoUser.getSession((err: Error | null, session: CognitoUserSession) => {
      if (err) {
        return reject(err);
      }

      const accessToken = session.getAccessToken().getJwtToken();
      return resolve(accessToken);
    });
  });
};

export const signout = async (): Promise<void> => {
  const userPool = new CognitoUserPool(poolData);
  const cognitoUser = userPool.getCurrentUser();

  if (cognitoUser !== null) {
    return new Promise((resolve) => {
      cognitoUser.signOut(() => {
        return resolve();
      });
    });
  }
};

export const forgottenTrigger = async ({ email }: any) => {
  const userPool = new CognitoUserPool(poolData);
  const userData = {
    Username: email,
    Pool: userPool,
  };

  const cognitoUser = new CognitoUser(userData);

  return new Promise((resolve, reject) => {
    cognitoUser.forgotPassword({
      onSuccess: function (data) {
        resolve(data);
      },
      onFailure: function (err) {
        console.error(err.message || JSON.stringify(err));
        reject();
      },
    });
  });
};

export const forgottenConfirm = async ({ email, code, newPassword }: any) => {
  const userPool = new CognitoUserPool(poolData);
  const userData = {
    Username: email,
    Pool: userPool,
  };

  const cognitoUser = new CognitoUser(userData);

  return new Promise((resolve, reject) => {
    cognitoUser.confirmPassword(code, newPassword, {
      onSuccess() {
        resolve("Password confirmed!");
      },
      onFailure(err) {
        console.error("Error confirming password", err);
        reject("Password not confirmed!");
      },
    });
  });
};
