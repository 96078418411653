import * as React from "react";
import { CloseIcon, HamburgerIcon } from "@chakra-ui/icons";
import {
  Flex,
  Stack,
  IconButton,
  Link,
  useBreakpointValue,
} from "@chakra-ui/react";
import { DesktopNav } from "./desktop";
import { PrimaryButton } from "../Button";

export const TopNav = ({
  onToggle,
  isOpen,
  items,
  navigate,
  suiteId,
  role,
}: any) => {
  return (
    <Flex
      color="brand.light"
      borderBottom={1}
      borderStyle={"solid"}
      borderColor="brand.bg"
      align={"center"}
    >
      <Flex
        flex={{ base: 1, md: "auto" }}
        ml={{ base: -2 }}
        display={{ base: "flex", md: "none" }}
      >
        <IconButton
          onClick={onToggle}
          icon={
            isOpen ? <CloseIcon w={3} h={3} /> : <HamburgerIcon w={5} h={5} />
          }
          variant={"ghost"}
          aria-label={"Toggle Navigation"}
        />
      </Flex>
      <Flex flex={{ base: 1 }} justify={{ base: "center", md: "start" }}>
        <Link
          textAlign={useBreakpointValue({ base: "center", md: "left" })}
          fontFamily={"heading"}
          color="brand.primary"
          fontSize="xl"
          width="150px"
          fontWeight="extrabold"
          href="/"
          rel="canonical"
        >
          TesterPlan
        </Link>

        <Flex display={{ base: "none", md: "flex" }} ml={10}>
          <DesktopNav
            items={items}
            linkColor="brand.text.primary"
            linkHoverColor="brand.primary.main"
          />
        </Flex>
      </Flex>
      {suiteId?.length > 0 && (
        <Stack
          flex={{ base: 1, md: 0 }}
          justify={"flex-end"}
          direction={"row"}
          spacing={6}
        >
          <PrimaryButton
            size="md"
            onClick={() => navigate(`/app/${suiteId}/admin`)}
          >
            Share
          </PrimaryButton>
        </Stack>
      )}
    </Flex>
  );
};
