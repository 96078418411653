import {
  Box,
  Flex,
  MenuItem,
  Menu,
  MenuList,
  MenuButton,
  Link,
  IconButton,
} from "@chakra-ui/react";
import * as React from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import { HamburgerIcon } from "@chakra-ui/icons";

import {
  del as delFeature,
  list as listFeatures,
} from "../../api/feature";
import { useTestCase } from "../../hooks/useTestCase";
import { RBP } from "../RBP";

export const FeatureList = () => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { suiteId = "" } = useParams();
  const { data } = useQuery(
    ["features", suiteId],
    () => listFeatures({ suiteId }),
    {
      placeholderData: [],
    }
  );

  const delFeatureMutation = useMutation(delFeature, {
    onSuccess: () => {
      queryClient.invalidateQueries(["features"]);
    },
  });

  const { testCases } = useTestCase();

  const getCaseCountByFeature = (featureId: string) => {
    return testCases.cases.filter((c) =>
      (c?.features ?? []).includes(featureId)
    ).length;
  };

  return (
    <Box
      bg="brand.paper"
      border="1px solid"
      borderColor="gray.100"
      borderRadius="3"
    >
      <Flex
        fontWeight="bold"
        background="gray.100"
        textTransform="uppercase"
        fontSize="sm"
      >
        <Box width="60%" padding="2">
          Feature
        </Box>
        <Box width="20%" padding="2">
          # Test Cases
        </Box>
        <Box width="20%" padding="2">
          Options
        </Box>
      </Flex>
      {data.map((f: any, i: number) => (
        <Flex direction="row" key={i} _hover={{ bg: "gray.50" }}>
          <Box width="60%" padding="2">
            {f.name}
          </Box>
          <Box width="20%" padding="2">
            <Link href={`/app/${suiteId}/tc?feats=${f.id}`}>
              {getCaseCountByFeature(f.id)}
            </Link>
          </Box>
          <Box width="20%" padding="2">
            <Box height="100%">
              <Menu>
                <MenuButton
                  bg="brand.bg"
                  border="1px solid"
                  borderColor="gray.100"
                  as={IconButton}
                  icon={<HamburgerIcon />}
                  _hover={{
                    bg: "brand.bg",
                  }}
                  _active={{
                    bg: "gray.200",
                    transform: "scale(0.98)",
                    color: "white",
                  }}
                ></MenuButton>
                <MenuList bg="brand.bg">
                  <OptionMenuItem
                    onClick={() => navigate(`/app/${suiteId}/tc?feats=${f.id}`)}
                  >
                    View Linked Test Cases
                  </OptionMenuItem>
                  <RBP admin collab>
                    <OptionMenuItem
                      onClick={() =>
                        delFeatureMutation.mutate({ suiteId, id: f.id })
                      }
                    >
                      Delete
                    </OptionMenuItem>
                  </RBP>
                </MenuList>
              </Menu>
            </Box>
          </Box>
        </Flex>
      ))}
    </Box>
  );
};

const OptionMenuItem = (props: any) => {
  return (
    <MenuItem
      bg="brand.bg"
      color="brand.text.primary"
      _focus={{
        bg: "brand.bg",
        color: "brand.primary.main",
      }}
      _hover={{
        bg: "brand.bg",
        color: "brand.primary.main",
      }}
      _active={{
        bg: "brand.bg",
        transform: "scale(0.98)",
        color: "brand.primary.main",
      }}
      {...props}
    ></MenuItem>
  );
};
