import axios from "axios";
import { getAccessToken } from "./signin";
import { remote } from "./config";

type CreateInput = {};
type UpdateInput = {
  suiteId: string;
  name: string;
};

type GetInput = {
  id: string;
};

export const list = async () => {
  const accessToken = await getAccessToken();
  const response = await axios.get(`${remote}/api/generation`, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });

  return response.data.v;
};

export const get = async (data: GetInput) => {
  if (!data.id) return {};
  const response = await axios.get(`${remote}/api/suite/${data.id}`, {});
  return response.data.v;
};

export const create = async (data: CreateInput) => {
  const url = `${remote}/api/suite`;
  const response = await axios.put(url, data, {});

  return response.data.v;
};

export const update = async (data: UpdateInput) => {
  const url = `${remote}/api/suite/${data.suiteId}`;
  const response = await axios.post(url, data);
  return response.data.v;
};
