import * as React from "react";
import { useMemo } from "react";
import { useSuite } from "../../hooks/useSuite";

export const RBP = ({ children, admin, collab, executor, view }: any) => {
  const { suite: data } = useSuite();

  const canDisplay = useMemo(() => {
    if (admin && data.role === "admin") return true;
    if (collab && data.role === "collab") return true;
    if (executor && data.role === "executor") return true;
    if (view && data.role === "view") return true;

    return false;
  }, [data.role, admin, collab, executor, view]);

  return <>{canDisplay && children}</>;
};
