// @ts-ignore
import * as env from "../../env/dev.env";

export const poolData = {
  UserPoolId: env.USER_POOL_ID,
  ClientId: env.USER_POOL_CLIENT_ID,
};

export const remote = env.API;

export const stripePayment = "";

export const stripeTableId = "";
export const stripeTableKey = "";
