import * as React from "react";
import { useMemo } from "react";
import {
  Grid,
  GridItem,
  Flex,
  Box,
  Heading,
  Text,
  useDisclosure,
  VStack,
  Divider,
  Icon,
} from "@chakra-ui/react";

import { topNav } from "../MainLayout/navItems";

import { useLocalStorage } from "../../hooks/useLocalStorage";
import { useNavigate } from "react-router-dom";
import { InfoButton, PrimaryButton } from "../Button";
import { TopNav } from "../MainLayout/TopNav";
import { CheckIcon } from "@chakra-ui/icons";

const ctaTextNew = "Get Started For Free, No Sign Ups!";
const ctaTextExisting = "Create New Suite - Free";

export const Home = () => {
  const [storedValue] = useLocalStorage();
  const navigate = useNavigate();
  const getName = (id: string) => storedValue.suiteNames[id] || "Untitled";
  const getCreated = (id: string) => {
    const c = storedValue.suiteCreated[id];
    if (c) {
      return `Created: ${new Date(c).toLocaleString()}`;
    }
    return "";
  };

  const { isOpen, onToggle } = useDisclosure();

  const topItems = useMemo(() => {
    const generated = topNav();
    return generated;
  }, [topNav]);

  return (
    <Box>
      <Grid
        templateColumns="repeat(12, 1fr)"
        gap={2}
        pt={3}
        bg="white"
        borderBottom="brand.bg"
        boxShadow="0px 0px 2px 0px #888888"
      >
        <GridItem colSpan={2}></GridItem>
        <GridItem colSpan={8}>
          <TopNav
            isOpen={isOpen}
            onToggle={onToggle}
            items={topItems}
            navigate={navigate}
            suiteId=""
          />
        </GridItem>
        <GridItem colSpan={2}></GridItem>
      </Grid>
      <Grid templateColumns="repeat(12, 1fr)" gap={2} pt={3}>
        <GridItem colSpan={2}></GridItem>
        <GridItem colSpan={8}>
          <Heading as="h1" mt="4" color="brand.primary.main">
            Fast, effective, free test planning
          </Heading>
          <VStack alignItems="left" mt="6">
            <Text>
              <CheckIcon mr="2" color="brand.success.main" />
              Plan your test cases
            </Text>
            <Text>
              <CheckIcon mr="2" color="brand.success.main" />
              Filter by features
            </Text>
            <Text>
              <CheckIcon mr="2" color="brand.success.main" />
              Record results against test cases
            </Text>
            <Text>
              <CheckIcon mr="2" color="brand.success.main" />
              Assign test cases to plans
            </Text>
            <Text>
              <CheckIcon mr="2" color="brand.success.main" />
              Role-based permission sharing links
            </Text>
          </VStack>
          <PrimaryButton onClick={() => navigate(`/app/new`)} mt="7">
            {storedValue?.suites?.length > 0 ? ctaTextExisting : ctaTextNew}
          </PrimaryButton>
          <Divider mt="10" mb="8" />
          {storedValue?.suites?.length > 0 && (
            <>
              <Heading as="h2" size="md">
                Your recent test suites
              </Heading>
              <Grid templateColumns="repeat(3, 1fr)" gap="4" mt="4">
                {storedValue?.suites?.map((s: string) => {
                  return (
                    <GridItem key={s} colSpan={[3, 3, 3, 1]}>
                      <Box bg="gray.100" padding="3" height="140px">
                        <Box height="80px">
                          <Text fontWeight="bold">{getName(s)}</Text>
                          <Text fontSize="small">{getCreated(s)}</Text>
                        </Box>
                        <InfoButton
                          onClick={() => navigate(`/app/${s}/tc`)}
                          float="right"
                        >
                          Enter
                        </InfoButton>
                      </Box>
                    </GridItem>
                  );
                })}
              </Grid>
            </>
          )}
        </GridItem>
        <GridItem colSpan={2}></GridItem>
      </Grid>
    </Box>
  );
};
