import { useState } from "react";
import { useQuery, useMutation, useQueryClient } from "react-query";
import { create, update, list, del } from "../api/plan";
import { useParams } from "react-router-dom";

export const usePlan = () => {
  const { suiteId = "" } = useParams();

  const queryClient = useQueryClient();
  const createMutation = useMutation(create, {});
  const updateMutation = useMutation(update, {});
  const delMutation = useMutation(del, {});

  const { data, refetch } = useQuery(
    ["plan", suiteId],
    () => list({ suiteId }),
    {
      placeholderData: [],
      onSuccess: (response) => {
        setPlans(response);
      },
    }
  );

  const [plans, setPlans] = useState(data);

  const deletePlan = async (planId: string) => {
    await delMutation.mutateAsync(
      { id: planId, suiteId },
      {
        onSuccess: () => {
          refetch();
        },
      }
    );
  };

  const add = async (name: string) => {
    if (name.length === 0) return;
    if (plans.find((x: any) => x?.name?.toUpperCase() === name.toUpperCase()))
      return;
    await createMutation.mutateAsync({
      name,
      suiteId,
    });

    refetch();
  };

  const updatePlan = async (plan: any) => {
    await updateMutation.mutateAsync({
      name: plan.name,
      id: plan.id,
      suiteId,
      testCases: plan.testCases ?? [],
    });

    refetch();
  };

  return {
    deletePlan,
    isDeleting: delMutation.isLoading,
    add,
    plans,
    isAdding: createMutation.isLoading,
    updatePlan,
    isUpdating: updateMutation.isLoading,
  };
};
