import { Box, Flex, Button, Input } from "@chakra-ui/react";
import * as React from "react";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { usePlan } from "../../hooks/usePlan";
import { PrimaryButton } from "../Button";

export const PlanList = () => {
  const { suiteId = "" } = useParams();
  const [addPlan, setAddPlan] = useState("");
  const navigate = useNavigate();
  const { plans, add, isAdding } = usePlan();
  const submit = async () => {
    await add(addPlan);
    setAddPlan("");
  };

  return (
    <Box
      bg="brand.paper"
      border="1px solid"
      borderColor="gray.100"
      borderRadius="3"
    >
      <Flex
        fontWeight="bold"
        background="gray.100"
        textTransform="uppercase"
        fontSize="sm"
      >
        <Box width="60%" padding="2">
          Plan
        </Box>
        <Box width="20%" padding="2">
          # Test Cases
        </Box>
        <Box width="20%" padding="2">
          Options
        </Box>
      </Flex>
      {plans.map((p: any, i: number) => (
        <Flex direction="row" key={i} _hover={{ bg: "gray.50" }}>
          <Box width="60%" padding="2">
            {p.name}
          </Box>
          <Box width="20%" padding="2">
            {p?.testCases?.length}
          </Box>
          <Box width="20%" padding="2">
            <Button onClick={() => navigate(`/app/${suiteId}/plan/${p.id}`)}>
              More
            </Button>
          </Box>
        </Flex>
      ))}
      <Flex direction="row" _hover={{ bg: "gray.50" }}>
        <Box width="80%" padding="2">
          <Input
            value={addPlan}
            onChange={(e: any) => setAddPlan(e.target.value)}
          />
        </Box>
        <Box width="20%" padding="2">
          <PrimaryButton onClick={submit} isLoading={isAdding}>
            Add
          </PrimaryButton>
        </Box>
      </Flex>
    </Box>
  );
};
