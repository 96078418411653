import { Flex, VStack, Box, Heading } from "@chakra-ui/react";
import * as React from "react";
import { useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { usePlan } from "../../hooks/usePlan";
import { useTestCase } from "../../hooks/useTestCase";
import { PrimaryButton, WarningButton } from "../Button";
import { ConfirmButton } from "../Button/ConfirmButton";
import { Card } from "../Card";
import { Select } from "chakra-react-select";
import { TestCaseTable } from "../TestCase";
import { useResults } from "../../hooks/useResults";

export const PlanDetail = () => {
  const { planId, suiteId } = useParams();
  const navigate = useNavigate();

  const { plans, deletePlan, updatePlan, isUpdating } = usePlan();

  const { testCases } = useTestCase();
  const { getLatestResult } = useResults();
  const [selectedCase, setSelectedCase] = useState(null);

  const plan = useMemo(() => {
    return plans.find((x: any) => x.id === planId);
  }, [plans, planId]);

  const delPlan = async (planId: string) => {
    await deletePlan(planId);
    navigate(`/app/${suiteId}/plan`);
  };

  const addTC = () => {
    if (!selectedCase) return;
    plan.testCases = plan.testCases ?? [];
    if (!plan.testCases.includes(selectedCase.id)) {
      plan.testCases.push(selectedCase.id);
      updatePlan(plan);
    }

    setSelectedCase(null);
  };

  const delTC = (tcId: string) => {
    plan.testCases = plan.testCases.filter((tc: any) => tc !== tcId);
    updatePlan(plan);
  };

  const filteredCases = useMemo(() => {
    if (!plan?.testCases || !testCases?.cases) return [];
    return plan.testCases.map((tc: any) => {
      return testCases.cases.find((c) => c.id === tc);
    });
  }, [testCases?.cases, plan]);

  const tcTableProps = {
    filteredCases,
    role: "view",
    getLatestResult,
    flags: ["results"],
    suiteId,
    action1Label: "Remove from plan",
    action1Fn: delTC,
  };

  return (
    <VStack>
      <Flex width="100%">
        <Box width="80%">
          <Heading>{plan?.name}</Heading>
        </Box>
        <Box width="20%">
          <ConfirmButton
            title="Delete Plan"
            description="Are you sure you want to delete this plan?"
            cancel="Cancel"
            affirmative="Confirm"
            onConfirm={() => delPlan(planId ?? "")}
          >
            <WarningButton float="right">Delete Plan</WarningButton>
          </ConfirmButton>
        </Box>
      </Flex>
      <Card width="100%" title="Test Cases">
        <Box>
          <Flex width="100%" mb="10px">
            <Box width="80%">
              <Select
                options={testCases.cases}
                getOptionValue={({ id }) => id}
                getOptionLabel={({ name, number = "" }) => `${number}: ${name}`}
                value={selectedCase}
                onChange={setSelectedCase}
              />
            </Box>
            <Box width="20%">
              <PrimaryButton onClick={addTC}>Add</PrimaryButton>
            </Box>
          </Flex>
        </Box>
        <Box
          bg="brand.paper"
          border="1px solid"
          borderColor="gray.100"
          borderRadius="3"
        >
          <TestCaseTable {...tcTableProps} />
        </Box>
      </Card>
    </VStack>
  );
};
