import * as React from "react";
import { useState } from "react";
import {
  FormControl,
  FormLabel,
  Input,
  Text,
  Button,
  Container,
  Grid,
  GridItem,
} from "@chakra-ui/react";

import { useMutation, useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";

import { signup, confirmRegistration } from "../../api/signup";
import { PrimaryButton } from "../Button";

export const Register = () => {
  const navigate = useNavigate();
  const signin = () => navigate("/signin");
  const signupApi = useMutation(signup, {
    onSuccess: () => setStage(2),
  });

  const queryClient = useQueryClient();

  const confirmRegistrationApi = useMutation(confirmRegistration, {
    onSuccess: () => {
      queryClient.invalidateQueries("user");
      navigate("/app/form");
    },
  });

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [code, setCode] = useState("");
  const [stage, setStage] = useState(1);

  const finalise = () => {
    confirmRegistrationApi.mutate({
      //@ts-ignore
      cognitoUser: signupApi.data,
      code,
      password,
    });
  };

  return (
    <Container>
      <Grid templateColumns="repeat(3, 1fr)" gap={2} mt={3}>
        <GridItem colSpan={3}>
          <Text fontSize="4xl">Register</Text>
        </GridItem>
        <GridItem colSpan={3}>
          {stage === 1 && (
            <FormControl>
              <FormLabel>
                Email
                <Input
                  type="email"
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                />
              </FormLabel>
              <FormLabel>
                Password
                <Input
                  type="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
              </FormLabel>
              <PrimaryButton
                bg="brand.primary"
                type="submit"
                onClick={() => signupApi.mutate({ username, password })}
              >
                Register
              </PrimaryButton>
              <Button
                ml="2"
                border="2px"
                borderRadius="5px"
                borderColor="brand.primary"
                bg="brand.light"
                color="brand.primary"
                display="inline"
                onClick={signin}
              >
                Sign In
              </Button>
            </FormControl>
          )}
          {stage === 2 && (
            <FormControl>
              <FormLabel>
                A confirmation code has been sent to your email
                <Input
                  type="text"
                  value={code}
                  onChange={(e) => setCode(e.target.value)}
                />
              </FormLabel>
              <PrimaryButton type="submit" onClick={finalise}>
                Confirm
              </PrimaryButton>
            </FormControl>
          )}
        </GridItem>
      </Grid>
    </Container>
  );
};
