import { useQuery, useMutation } from "react-query";

import { get as getSuite, update } from "../api/suite";
import { useParams } from "react-router-dom";
import { useLocalStorage } from "../hooks/useLocalStorage";
import { uniq } from "ramda";

export const useSuite = () => {
  const { suiteId = "" } = useParams();
  const [store, setLocalStore] = useLocalStorage();

  const { data, isFetched, refetch } = useQuery(
    ["suite", suiteId],
    () => getSuite({ id: suiteId }),
    {
      placeholderData: [],
      onSuccess: (response) => {
        setLocalStore({
          ...store,
          suites: uniq([suiteId, ...store.suites]),
          suiteNames: { ...store.suiteNames, [suiteId]: response.name },
          suiteCreated: {
            ...store.suiteCreated,
            [suiteId]: response.createdAt,
          },
        });
      },
    }
  );

  const updateNameApi = useMutation(update, {
    onSuccess: () => {
      refetch();
    },
  });

  const updateSuite = (x: any) => {
    updateNameApi.mutate(x);
  };

  return {
    suite: data,
    refetchSuite: refetch,
    isSuiteFetched: isFetched,
    updateSuite,
  };
};
