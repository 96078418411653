import * as React from "react";
import { useEffect } from "react";
import { useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";

import { create } from "../../api/suite";
import { useLocalStorage } from "../../hooks/useLocalStorage";

export const NewForm = () => {
  const [storedValue, setStore] = useLocalStorage();
  const qC = useQueryClient();

  useEffect(() => {
    const run = async () => {
      const data = await qC.fetchQuery("suite", () => create({}));
      if (data.suiteId) {
        setStore({
          suites: [...new Set([...storedValue.suites, data.suiteId])],
          isLoading: false,
        });
        navigate(`/app/${data.suiteId}/tc?first=true`);
      }
    };

    run();
  }, []);

  const navigate = useNavigate();

  return <>Loading...</>;
};
