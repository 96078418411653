import * as React from "react";
import { useEffect, useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import { useParams } from "react-router-dom";
import { Card } from "../Card";
import { RBP } from "../RBP";
import { useBoolean, useCopyToClipboard } from "usehooks-ts";
import {
  VStack,
  Flex,
  Link,
  Text,
  HStack,
  Skeleton,
  Button,
  ButtonGroup,
  IconButton,
  Input,
} from "@chakra-ui/react";

import { create as createToken, Role } from "../../api/token";
import { CheckIcon, CloseIcon, EditIcon } from "@chakra-ui/icons";
import { useSuite } from "../../hooks/useSuite";

const makeLink = (suiteId: string) =>
  `${window.location.protocol}//${window.location.host}/app/${suiteId}`;

const makeShare = (suiteId: string) => ({
  title: "TesterPlan",
  url: makeLink(suiteId),
});

const LinkDisplay = ({ token, copy, shareSuite, revoke }: any) => {
  return (
    <Flex width="80%">
      <Link href={makeLink(token.tokenId)} width="70%" target="_blank">
        <Text noOfLines={1}>{makeLink(token.tokenId)}</Text>
      </Link>
      <ButtonGroup isAttached width="30%" size="sm">
        <Button onClick={() => copy(makeLink(token.tokenId))}>Copy</Button>
        <Button onClick={() => shareSuite(token.tokenId)}>Share</Button>
        <Button onClick={() => revoke(token.tokenId)}>Revoke</Button>
      </ButtonGroup>
    </Flex>
  );
};

export const Admin = () => {
  const { suite, isSuiteFetched, updateSuite } = useSuite();
  const editName = useBoolean(false);
  const hasCollab = useBoolean(false);
  const hasExecutor = useBoolean(false);
  const hasView = useBoolean(false);
  const [, copy] = useCopyToClipboard();
  const queryClient = useQueryClient();
  const { suiteId = "" } = useParams();
  const [name, setName] = useState("");

  const submitName = () => {
    updateSuite({ suiteId, name });
    editName.setFalse();
  };

  useEffect(() => {
    setName(suite.name);
  }, [suite.name]);

  useEffect(() => {
    (suite?.tokens ?? []).forEach((t: any) => {
      if (t.role === "executor") hasExecutor.setTrue();
      if (t.role === "view") hasView.setTrue();
      if (t.role === "collab") hasCollab.setTrue();
    });
  }, [hasExecutor, hasView, hasCollab, suite.tokens]);

  const linkMutation = useMutation(createToken, {
    onSuccess: () => {
      queryClient.invalidateQueries(["suite", suiteId]);
    },
  });

  const shareSuite = (suiteId: string) => {
    if (navigator.share) {
      navigator.share(makeShare(suiteId));
    } else {
      window.open(`mailto:?subject="TesterPlan Link"`, "_blank");
    }
  };

  const generate = (role: Role) => {
    linkMutation.mutate({ role, suiteId }, {});
  };

  const revoke = (role: Role) => {
    //linkMutation.mutate({ role, suiteId }, {});
  };

  return (
    <VStack>
      <Card title="Test Suite" width="100%">
        <Skeleton isLoaded={isSuiteFetched}>
          <VStack alignItems="left">
            <HStack>
              <Text fontWeight="bold">Name:</Text>
              {!editName.value && <Text>{suite?.name || "Untitled"}</Text>}
              <RBP admin>
                {editName.value ? (
                  <>
                    <Input
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                    />
                    <IconButton
                      onClick={submitName}
                      icon={<CheckIcon />}
                      aria-label="save name"
                      bg="brand.primary.main"
                      color="brand.paper"
                    />
                    <IconButton
                      onClick={() => editName.setFalse()}
                      icon={<CloseIcon />}
                      aria-label="cancel edit name"
                      bg="brand.warning.main"
                      color="brand.paper"
                    />
                  </>
                ) : (
                  <IconButton
                    onClick={() => editName.setTrue()}
                    icon={<EditIcon />}
                    aria-label="edit name"
                  />
                )}
              </RBP>
            </HStack>
            <HStack>
              <Text fontWeight="bold">Created:</Text>
              <Text>{new Date(suite.createdAt).toLocaleString()}</Text>
            </HStack>
          </VStack>
        </Skeleton>
      </Card>
      <RBP admin>
        <Card title="Links" width="100%">
          <Skeleton isLoaded={isSuiteFetched}>
            <VStack alignItems="left">
              {suite.suiteId && (
                <>
                  <Flex direction="row">
                    <Text fontWeight="bold" width="20%">
                      Admin
                    </Text>
                    <Flex width="80%">
                      <Link
                        href={makeLink(suite.suiteId)}
                        width="70%"
                        target="_blank"
                      >
                        <Text noOfLines={1}>{makeLink(suite.suiteId)}</Text>
                      </Link>
                      <ButtonGroup isAttached width="30%" size="sm">
                        <Button onClick={() => copy(makeLink(suite.suiteId))}>
                          Copy
                        </Button>
                        <Button onClick={() => shareSuite(suite.suiteId)}>
                          Share
                        </Button>
                      </ButtonGroup>
                    </Flex>
                  </Flex>
                  <Flex>
                    <Text fontWeight="bold" width="20%"></Text>
                    <Text width="80%" fontSize="xs">
                      Allows anyone with this link to have top level of access
                    </Text>
                  </Flex>
                </>
              )}
              <Flex>
                <Text fontWeight="bold" width="20%">
                  Collaborator
                </Text>
                {hasCollab.value ? (
                  <LinkDisplay
                    token={suite.tokens.find((x: any) => x.role === "collab")}
                    copy={copy}
                    shareSuite={shareSuite}
                    revoke={revoke}
                  />
                ) : (
                  <Button onClick={() => generate("collab")}>Generate</Button>
                )}
              </Flex>
              <Flex>
                <Text fontWeight="bold" width="20%"></Text>
                <Text width="80%" fontSize="xs">
                  Allows anyone with this link to have edit/view access to
                  everything except for links
                </Text>
              </Flex>
              <Flex>
                <Text fontWeight="bold" width="20%">
                  Executor
                </Text>
                {hasExecutor.value ? (
                  <LinkDisplay
                    token={suite.tokens.find((x: any) => x.role === "executor")}
                    copy={copy}
                    shareSuite={shareSuite}
                    revoke={revoke}
                  />
                ) : (
                  <Button onClick={() => generate("executor")}>Generate</Button>
                )}
              </Flex>
              <Flex>
                <Text fontWeight="bold" width="20%"></Text>
                <Text width="80%" fontSize="xs">
                  Allows anyone with this link to execute and record results of
                  testing rounds without making changes to the test cases
                </Text>
              </Flex>
              <Flex>
                <Text fontWeight="bold" width="20%">
                  View Only
                </Text>
                {hasView.value ? (
                  <LinkDisplay
                    token={suite.tokens.find((x: any) => x.role === "view")}
                    copy={copy}
                    shareSuite={shareSuite}
                    revoke={revoke}
                  />
                ) : (
                  <Button onClick={() => generate("view")}>Generate</Button>
                )}
              </Flex>
              <Flex>
                <Text fontWeight="bold" width="20%"></Text>
                <Text width="80%" fontSize="xs">
                  Allows anyone with this link to view all the contents of this
                  suite without making changes
                </Text>
              </Flex>
            </VStack>
          </Skeleton>
        </Card>
      </RBP>
    </VStack>
  );
};
