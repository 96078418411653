import axios from "axios";
import { remote } from "./config";

export type Role = "admin" | "collab" | "executor" | "view";

type CreateInput = {
  suiteId: string;
  role: Role;
};

type DelInput = {
  suiteId: string;
  id: string;
};

export const create = async (data: CreateInput) => {
  const url = `${remote}/api/suite/${data.suiteId}/token`;
  const response = await axios.put(url, data, {});

  return response.data.v;
};

export const del = async (data: DelInput) => {
  const url = `${remote}/api/suite/${data.suiteId}/token/${data.id}`;
  const response = await axios.delete(url);
  return response.data.v;
};
