import { v4 as uuid } from "uuid";

export type TestSuite = {
  plans: TestPlan[];
  cases: TestCase[];
  name: string;
};

export type TestPlan = {
  name: string;
  description: string;
  techniques: TestTechnique[];
};

export type TestTechnique = {
  name: string;
  description: string;
};

export type Feature = {
  name: string;
  description: string;
};

export type FeatureRef = string;

export type TestCase = {
  id: string;
  tags: string[];
  features: FeatureRef[];
  name: string;
  _dirty?: boolean;
  _deleted?: boolean;
};

export type TestStep = {
  id: string | null;
  action: string;
  expected: Expectation[];
  children: TestStep[];
};

export type Expectation = {
  description: string;
};

export const makeCase = (): TestCase => ({
  id: uuid().split("-")[0],
  tags: [] as string[],
  features: [] as FeatureRef[],
  _dirty: false,
  name: "",
});

export const makeStep = (): TestStep => ({
  id: null, //uuid().split("-")[0],
  children: [],
  action: "",
  expected: [],
});

const isStepPopulated = (x: any) => {
  return x.children.length > 0 || x.action.length > 0 || x.expected.length > 0;
};
