import * as React from "react";
import { useState, useMemo, useEffect, useCallback } from "react";
import {
  Box,
  VStack,
  Text,
  Heading,
  Flex,
  useDisclosure,
} from "@chakra-ui/react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useParams, useSearchParams } from "react-router-dom";

import {
  create as createFeature,
  list as listFeatures,
} from "../../api/feature";

import { PrimaryButton } from "../Button";

import { RBP } from "../RBP";
import { useTestCase } from "../../hooks/useTestCase";
import { ChakraStylesConfig, Select } from "chakra-react-select";
import { intersection } from "ramda";
import { useResults } from "../../hooks/useResults";
import { useBoolean } from "usehooks-ts";
import { useSuite } from "../../hooks/useSuite";
import { TestCaseTable } from "../TestCase";
import { NewSuiteModal } from "../Onboarding/NewSuiteModal";

const featureSelectStyles: ChakraStylesConfig = {
  dropdownIndicator: (baseStyles) => ({
    ...baseStyles,
    background: "brand.bg",
  }),
  option: (baseStyles) => ({
    ...baseStyles,
    background: "brand.bg",
    _hover: {
      color: "brand.primary",
    },
  }),
  menuList: (baseStyles) => ({
    ...baseStyles,
    background: "brand.bg",
  }),
};

const IntroBox = ({ cases }: any) => {
  return (
    <Box
      bg="brand.paper"
      border="1px"
      borderColor="gray.100"
      padding="2"
      borderRadius="3"
      boxShadow="1px 1px gray.200"
    >
      {cases <= 5 && <Heading size="sm">Welcome!</Heading>}
      {cases > 5 && <Heading size="sm">Welcome back!</Heading>}

      {cases <= 5 && (
        <Flex direction="column">
          <Text width="100%">
            Don't let a signup get in the way of a good planning session. Just
            start typing below!
          </Text>
          <Text width="100%">
            When you're done, bookmark this page and use the sharing links to
            get your colleagues to help. Still no signups required.
          </Text>
        </Flex>
      )}

      <Flex direction="column">
        <Text width="100%">
          Use the Sharing button at the top to get your colleagues to help.
        </Text>
      </Flex>
    </Box>
  );
};

export const TestCase = () => {
  const { suiteId = "" } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const showFilters = useBoolean(false);
  const [sort, setSort] = useState("name");

  const { suite: suiteDetails } = useSuite();
  const { getLatestResult } = useResults();

  const {
    testCases,
    testCaseSaving,
    save,
    linkFeat,
    addCaseText,
    updateCaseTag,
    deleteCase,
  } = useTestCase();
  const [featFilter, _setFeatFilter] = useState([] as string[]);

  useEffect(() => {
    const timer = setInterval(() => {
      save();
    }, 1000 * 60);

    return () => clearInterval(timer);
  }, []);

  const setFeatFilter = (x: any) => {
    _setFeatFilter(x);
    setSearchParams({
      feats: x.map((y: any) => y.id).join(","),
    });
  };

  const queryClient = useQueryClient();
  const { data: featureData } = useQuery(
    ["features", suiteId],
    () => listFeatures({ suiteId }),
    {
      placeholderData: [],
    }
  );

  const createFeatMutation = useMutation(createFeature, {
    onSuccess: (data) => {
      queryClient.setQueryData(["features", suiteId], data);
    },
  });

  const filteredCases = useMemo(() => {
    const output = testCases.cases.filter((tc) => {
      // always show that empty row, but if filtered without the same feat
      // added it'll not show, so always show if it's dirty
      if (tc.name === "" || tc._dirty) return true;
      if (tc._deleted) return false;

      const definedFilterFeat = searchParams.get("feats");
      if (definedFilterFeat) {
        const filterFeats = definedFilterFeat.split(",");
        return intersection(tc?.features ?? [], filterFeats).length > 0;
      }

      return true;
    });

    if (!sort) return output;

    output.sort((a: any, b: any) => {
      return a.number - b.number;
    });

    return output;
  }, [testCases.cases, searchParams, sort]);

  const disclosure = useDisclosure();
  useEffect(() => {
    const first = searchParams.get("first");
    if (first && first === "true") {
      disclosure.onOpen();
    }
  }, [searchParams]);

  useEffect(() => {
    const featFilter = searchParams.get("feats");
    if (featFilter)
      _setFeatFilter(
        featFilter
          .split(",")
          .map((fId) => featureData.find((x: any) => x.id === fId))
      );
  }, [searchParams, featureData]);

  const addFeat = (name: string) => {
    return createFeatMutation.mutateAsync({ name, suiteId });
  };

  const tcTableProps = {
    filteredCases,
    role: suiteDetails.role,
    getLatestResult,
    flags: ["expectations", "results", "delete", "feature", "editname"],
    deleteCase,
    suiteId,
    addCaseText,
    updateCaseTag,
    featureData,
    addFeat,
    linkFeat,
    showFilters,
  };

  return (
    <VStack width="100%" align="left">
      <IntroBox cases={testCases.cases.length} />
      {showFilters.value && (
        <Box
          bg="brand.paper"
          border="1px"
          borderColor="gray.100"
          padding="2"
          borderRadius="3"
          boxShadow="1px 1px gray.200"
        >
          <Heading size="sm">Filters</Heading>
          <Flex>
            <Text width="20%">Features </Text>
            <Box width="80%">
              <Select
                isMulti
                value={featFilter}
                options={featureData}
                getOptionLabel={({ name }: any) => name}
                getOptionValue={({ id }: any) => id}
                onChange={(x) => setFeatFilter(x)}
                isClearable={false}
                placeholder="Select..."
                chakraStyles={featureSelectStyles}
              />
            </Box>
          </Flex>
        </Box>
      )}
      <Box
        bg="brand.paper"
        border="1px solid"
        borderColor="gray.100"
        borderRadius="3"
      >
        <TestCaseTable {...tcTableProps} />
      </Box>
      <RBP admin collab>
        <Box>
          <PrimaryButton
            onClick={() => save()}
            isLoading={testCaseSaving}
            loadingText="Saving..."
          >
            Save
          </PrimaryButton>
        </Box>
      </RBP>
      <NewSuiteModal disclosure={disclosure} suiteId={suiteId} />
    </VStack>
  );
};
