import { useBoolean, useToast } from "@chakra-ui/react";
import { useState, useEffect, useMemo } from "react";
import { useQuery, useMutation, useQueryClient } from "react-query";

import { create, update, list, rmCase } from "../api/testCase";
import { makeCase, TestCase } from "../app/testSuite";
import { any, omit } from "ramda";
import { useParams } from "react-router-dom";
import { useLocalStorage } from "../hooks/useLocalStorage";
import { useTracking } from "./useTracking";

const withSpareCase = (tcs: TestCase[]) => {
  const isEmpty = tcs.length === 0;
  if (isEmpty) return [makeCase()];
  const isLastOneUsed = tcs[tcs.length - 1].name !== "";
  const extraIfRequired = isLastOneUsed ? [makeCase()] : [];
  return [...tcs, ...extraIfRequired];
};

export const useTestCase = () => {
  const { suiteId = "" } = useParams();
  const [store, setLocalStore] = useLocalStorage();
  const { track } = useTracking();
  const toast = useToast();

  const queryClient = useQueryClient();
  const [testCaseSaving, submittingState] = useBoolean(false);

  const createMutation = useMutation(create, {});
  const updateMutation = useMutation(update, {});
  const delMutation = useMutation(rmCase, {});

  const { data, refetch } = useQuery(
    ["testCases", suiteId],
    () => list({ suiteId }),
    {
      placeholderData: [],
      onSuccess: (response) => {
        const newCases = [...response, ...testCases.cases];
        setTestCases({
          ...testCases,
          cases: withSpareCase(newCases),
        });
      },
    }
  );

  const [testCases, setTestCases] = useState({
    cases: [...(data ?? [])],
  });

  const isDirty = useMemo(() => {
    const result = any((x: any) => x._dirty)(testCases.cases);
    return result;
  }, [testCases.cases]);

  useEffect(() => {
    if (suiteId !== store.current) {
      setLocalStore({ ...store, current: suiteId });
      setTestCases({ cases: [] });
      refetch();
    }
  }, [suiteId, store.current]);

  const updateCase = (id: string, rest: any) => {
    const cases = testCases.cases.map((x) => {
      if (x.id === id) {
        return { ...x, ...rest };
      }

      return x;
    });

    setTestCases({
      ...testCases,
      cases: withSpareCase(cases),
    });

    return cases;
  };

  const saveExisting = async (cases: TestCase[]) => {
    const toUpdate = cases.filter(
      (x) => x.id.startsWith("tc_") && x._dirty && !x._deleted
    );

    for (const tc of toUpdate) {
      await updateMutation.mutateAsync(omit(["_dirty"], { ...tc, suiteId }));
    }

    return toUpdate.map((x) => x.id);
  };

  const saveNew = async (cases: TestCase[]) => {
    const toCreate = cases.filter(
      (x) => !x.id.startsWith("tc_") && x.name !== ""
    );

    const newIds: {
      [id: string]: string;
    } = {};

    for (const tc of toCreate) {
      await createMutation.mutateAsync(omit(["_dirty"], { ...tc, suiteId }), {
        onSuccess: (data) => {
          queryClient.setQueryData(["testCase", data.id], data);

          newIds[tc.id] = data.id;
        },
      });
    }

    return newIds;
  };

  const deleteCases = async (cases: TestCase[]) => {
    const toDelete = cases.filter((x) => x._deleted);

    for (const tc of toDelete) {
      await delMutation.mutateAsync(
        { id: tc.id, suiteId },
        {
          onSuccess: () => {
            queryClient.removeQueries(["testCase", tc.id], { exact: true });
          },
        }
      );
    }

    return toDelete.map((x) => x.id);
  };

  const save = async (cases?: any[]) => {
    submittingState.on();
    const casesToUse = cases ?? testCases.cases;
    if (testCases.cases.length === 0) {
      submittingState.off();
      return;
    }
    const [newIds, updatedIds, deleteIds] = await Promise.all([
      saveNew(casesToUse),
      saveExisting(casesToUse),
      deleteCases(casesToUse),
    ]);

    const out = casesToUse
      .filter((tc) => !deleteIds.includes(tc.id))
      .map((tc) => {
        if (newIds[tc.id]) {
          return {
            ...tc,
            id: newIds[tc.id],
          };
        }

        if (updatedIds.includes(tc.id)) {
          return { ...tc, _dirty: false };
        }

        return tc;
      });

    setTestCases({
      ...testCases,
      cases: withSpareCase(out),
    });

    track("saveTestCase", {
      count: Object.keys(newIds).length + updatedIds.length + deleteIds.length,
    });

    toast({
      title: "Test cases saved, don't forget to copy this url!",
      status: "success",
      duration: 5000,
      isClosable: true,
    });

    submittingState.off();
  };

  const linkFeat = (id: string, features: string[]) => {
    updateCase(id, { features, _dirty: true });
  };

  const addCaseText = (id: string, newValue: string) => {
    updateCase(id, { name: newValue, _dirty: true });
  };

  const updateCaseTag = (id: string, tags: string[]) => {
    updateCase(id, { tags, _dirty: true });
  };

  const deleteCase = (id: string) => {
    updateCase(id, { _dirty: true, _deleted: true });
  };

  return {
    //
    testCases,
    testCaseSaving,
    updateCase,
    save,
    isDirty,
    linkFeat,
    addCaseText,
    updateCaseTag,
    deleteCase,
    fetchCases: refetch
  };
};
