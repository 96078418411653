import * as React from "react";
import { useState, useMemo, useEffect } from "react";
import { Select } from "chakra-react-select";
import {
  Box,
  Flex,
  Heading,
  Button,
  FormLabel,
  Input,
  Switch,
  useToast,
  Checkbox,
  ButtonGroup,
} from "@chakra-ui/react";
import { useParams, useSearchParams } from "react-router-dom";

import { useResults } from "../../hooks/useResults";
import { useTestCase } from "../../hooks/useTestCase";
import { useBoolean } from "usehooks-ts";
import { TestCase } from "../../app/testSuite";

const outerStyle = {
  border: "1px solid",
  borderColor: "gray.100",
  borderRadius: "3",
  padding: "5",
};

const passedBtnStyle = {
  bg: "brand.success.main",
  color: "gray.50",
  _hover: {
    bg: "brand.success.main",
    filter: "brightness(1.1)",
  },
  _active: {
    bg: "brand.success.main",
    transform: "scale(0.98)",
    color: "white",
  },
};
const failedBtnStyle = {
  bg: "brand.warning.main",
  color: "gray.50",
  _hover: {
    bg: "brand.warning.main",
    filter: "brightness(1.1)",
  },
  _active: {
    bg: "brand.warning.main",
    transform: "scale(0.98)",
    color: "white",
  },
};

export const Add = ({ tcId, raw = false }: any) => {
  const { suiteId = "" } = useParams();
  const [searchParams] = useSearchParams();
  const { testCases } = useTestCase();
  const { addResult } = useResults();
  const [tc, setTC] = useState() as [
    TestCase | undefined,
    (x?: TestCase) => void
  ];
  const [tester, setTester] = useState("");
  const [comments, setComments] = useState("");
  const passed = useBoolean();
  const toast = useToast();

  const submitting = useBoolean();

  const buttonText = useMemo(
    () => (passed.value ? "Passed" : "Failed"),
    [passed.value]
  );

  const buttonStyle = useMemo(
    () => (passed.value ? passedBtnStyle : failedBtnStyle),
    [passed.value]
  );

  useEffect(() => {
    if (tcId?.length) {
      const found = testCases.cases.find((x) => x.id === tcId);
      if (found) setTC(found);
      return;
    }

    const definedParam = searchParams.get("tc");
    if (definedParam) {
      const found = testCases.cases.find((x) => x.id === definedParam);
      if (found) setTC(found);
    }
  }, [searchParams, testCases.cases, tcId]);

  useEffect(() => {
    if (!tc) return;
    const obj = (tc.expectations ?? []).reduce((acc, x) => {
      return {
        ...acc,
        [x.name]: false,
      };
    }, {});

    setExp(obj);
  }, [tc]);

  const setAllExp = () => {
    const out = {};
    for (const key in exp) {
      out[key] = true;
    }
    setExp(out);
  };

  const unsetAllExp = () => {
    const out = {};
    for (const key in exp) {
      out[key] = false;
    }

    setExp(out);
  };

  const [exp, setExp] = useState({} as any);

  const submit = async () => {
    submitting.setTrue();
    tc &&
      (await addResult({
        suiteId,
        testCaseId: tc.id,
        comments,
        tester,
        passed: passed.value,
        expectations: exp,
      }));
    submitting.setFalse();
    setTC();
    setExp({});
    setComments("");
    passed.setFalse();
    toast({
      title: "Submitted.",
    });
  };

  const setExpChanged = (e: any, checked: boolean) => {
    setExp({
      ...exp,
      [e.name]: checked,
    });
  };

  const outStyle = raw ? {} : outerStyle;

  return (
    <Box bg="brand.paper" {...outStyle}>
      {!raw && <Heading size="lg"> Add Test Result</Heading>}
      <Flex>
        <FormLabel width="10%">Test Case</FormLabel>
        <Box width="90%">
          <Select
            options={testCases.cases}
            getOptionLabel={({ name, number }: any) =>
              `${number ?? ""}: ${name}`
            }
            getOptionValue={({ id }: any) => id}
            placeholder="Select test case..."
            value={tc}
            onChange={setTC}
          />
        </Box>
      </Flex>
      {tc?.expectations?.length && (
        <Flex mt="10px">
          <FormLabel width="10%">Expectations</FormLabel>
          <Box width="90%">
            {(tc?.expectations ?? []).map((_exp: any, i: number) => {
              return (
                <Checkbox
                  key={i}
                  display="block"
                  isChecked={exp[_exp.name]}
                  onChange={(e) => setExpChanged(_exp, e.target.checked)}
                >
                  {_exp.name}
                </Checkbox>
              );
            })}
            <ButtonGroup isAttached display="block">
              <Button size="xs" onClick={setAllExp}>
                All
              </Button>
              <Button size="xs" onClick={unsetAllExp}>
                None
              </Button>
            </ButtonGroup>
          </Box>
        </Flex>
      )}
      <Flex mt="10px">
        <FormLabel width="10%">Passed</FormLabel>
        <Switch width="90%" isChecked={passed.value} onChange={passed.toggle} />
      </Flex>
      <Flex mt="10px">
        <FormLabel width="10%">Comments</FormLabel>
        <Input
          width="90%"
          onChange={(e: any) => setComments(e.target.value)}
          value={comments}
        />
      </Flex>
      <Flex mt="10px">
        <FormLabel width="10%">Tester</FormLabel>
        <Input
          width="90%"
          onChange={(e: any) => setTester(e.target.value)}
          value={tester}
        />
      </Flex>

      <Box mt="10px">
        <Button onClick={submit} {...buttonStyle} disabled={submitting.value}>
          Submit {buttonText} Result
        </Button>
      </Box>
    </Box>
  );
};
