import * as React from "react";
import { Box, Center, Icon, Flex } from "@chakra-ui/react";
import { CaseRow } from "./TestCaseRow";
import { FiFilter } from "react-icons/fi";

export const TestCaseTable = ({
  flags,
  filteredCases,
  role,
  getLatestResult,
  deleteCase = () => {},
  suiteId,
  addCaseText = () => {},
  featureData = [],
  addFeat = () => {},
  linkFeat = () => {},
  showFilters = () => {},
  action1Label = "",
  action1Fn = () => {},
}: any) => {
  const caseActions = {
    deleteCase,
    suiteId,
    addCaseText,
    featureData,
    addFeat,
    linkFeat,
    role,
    action1Label,
    action1Fn,
  };

  const nameWidth = flags.includes("feature") ? "40%" : "85%";

  return (
    <React.Fragment>
      <Flex
        fontWeight="bold"
        background="gray.100"
        textTransform="uppercase"
        fontSize="sm"
      >
        <Box width="5%" padding="2">
          <Center>Id</Center>
        </Box>
        <Box width={nameWidth} padding="2">
          Test Case
        </Box>
        {flags.includes("feature") && (
          <Box width="45%" padding="2">
            Feature
            <Icon
              ml="2"
              as={FiFilter}
              cursor="pointer"
              onClick={showFilters.toggle}
            />
          </Box>
        )}
        <Box width="10%" padding="2">
          <Center>...</Center>
        </Box>
      </Flex>
      {filteredCases.map((x: any) => {
        if (!x || x?._deleted) return;

        return (
          <CaseRow
            key={x.id}
            flags={flags}
            {...caseActions}
            caseDetails={x}
            tcLastResult={getLatestResult(x.id)}
          />
        );
      })}
    </React.Fragment>
  );
};
