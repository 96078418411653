//https://www.npmjs.com/package/amazon-cognito-identity-js

import {
  CognitoUserPool,
  CognitoUserAttribute,
  CognitoUser,
  AuthenticationDetails,
} from "amazon-cognito-identity-js";

import { poolData } from "./config";

type SignupVars = {
  username: string;
  password: string;
};

type ConfirmVars = {
  cognitoUser: CognitoUser;
  code: string;
  password: string;
};

export const signup = async ({ username, password }: SignupVars) => {
  const userPool = new CognitoUserPool(poolData);
  const attributeList: CognitoUserAttribute[] = [
    new CognitoUserAttribute({
      Name: "email",
      Value: username,
    }),
  ];

  return new Promise((resolve, reject) => {
    userPool.signUp(username, password, attributeList, [], (err, result) => {
      if (err) {
        return reject(err);
      }

      if (!result) {
        return reject(new Error("Cognito user not found"));
      }

      const cognitoUser = result.user;
      return resolve(cognitoUser);
    });
  });
};

export const confirmRegistration = async ({
  cognitoUser,
  code,
  password,
}: ConfirmVars) => {
  return new Promise((resolve, reject) => {
    cognitoUser.confirmRegistration(code, true, (err, result) => {
      if (err) {
        return reject(err);
      }

      cognitoUser.authenticateUser(
        new AuthenticationDetails({
          Username: cognitoUser.getUsername(),
          Password: password,
        }),
        {
          onSuccess: () => {
            console.log("Post registration, login success");
            resolve(result);
          },
          onFailure: (err) => {
            console.error("Post registration, login error", err);
            reject(err);
          },
        }
      );
    });
  });
};
