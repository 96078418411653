import * as React from "react";
import {
  Modal,
  ModalHeader,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  ModalBody,
} from "@chakra-ui/react";
import { Add } from "./Add";

export const AddResultModal = ({ disclosure, tcId }) => {
  const { isOpen, onClose } = disclosure;

  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent maxWidth="80%">
          <ModalHeader>Add Result</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Add tcId={tcId} raw={true} />
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};
