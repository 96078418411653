import * as React from "react";
import { useState } from "react";
import {
  FormControl,
  FormLabel,
  Input,
  Button,
  Container,
  Grid,
  GridItem,
} from "@chakra-ui/react";

import { useMutation, useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";

import { forgottenTrigger, forgottenConfirm } from "../../api/signin";
import { PrimaryButton } from "../Button";

export const Forgotten = () => {
  const navigate = useNavigate();
  const triggerApi = useMutation(forgottenTrigger, {
    onSuccess: () => setStage(2),
  });

  const queryClient = useQueryClient();

  const confirmApi = useMutation(forgottenConfirm, {
    onSuccess: () => {
      queryClient.invalidateQueries("user");
      navigate("/signin");
    },
  });

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [code, setCode] = useState("");
  const [stage, setStage] = useState(1);

  const finalise = () => {
    confirmApi.mutate({
      email: username,
      newPassword: password,
      code,
    });
  };

  return (
    <Container>
      <Grid templateColumns="repeat(3, 1fr)" gap={2}>
        <GridItem colSpan={3}>Forgotten Password</GridItem>
        <GridItem colSpan={3}>
          {stage === 1 && (
            <FormControl>
              <FormLabel>
                Email
                <Input
                  type="email"
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                />
              </FormLabel>
              <PrimaryButton
                type="submit"
                onClick={() => triggerApi.mutate({ email: username })}
              >
                Verify Email
              </PrimaryButton>
            </FormControl>
          )}
          {stage === 2 && (
            <FormControl>
              <FormLabel>
                A confirmation code has been sent to your email
                <Input
                  type="text"
                  value={code}
                  onChange={(e) => setCode(e.target.value)}
                />
              </FormLabel>
              <FormLabel>
                New Password
                <Input
                  type="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
              </FormLabel>
              <PrimaryButton type="submit" onClick={finalise}>
                Confirm
              </PrimaryButton>
            </FormControl>
          )}
        </GridItem>
      </Grid>
    </Container>
  );
};
