import * as React from "react";
import { useEffect, useState } from "react";
import {
  Flex,
  Text,
  Icon,
  Box,
  MenuList,
  Menu,
  IconButton,
  MenuButton,
  MenuItem,
  useToast,
} from "@chakra-ui/react";
import { useResults } from "../../hooks/useResults";
import { useTestCase } from "../../hooks/useTestCase";
import { useSearchParams } from "react-router-dom";
import { RBP } from "../RBP";
import { HamburgerIcon } from "@chakra-ui/icons";
import { ConfirmButton } from "../Button/ConfirmButton";
import { BsCheckCircle, BsCircle } from "react-icons/bs";

export const ResultList = ({
  filteredResults,
  testCases,
  deleteResult,
}: any) => {
  return (
    <Box
      bg="brand.paper"
      border="1px solid"
      borderColor="gray.100"
      borderRadius="3"
    >
      <Flex
        direction="row"
        fontWeight="bold"
        background="gray.100"
        textTransform="uppercase"
        fontSize="sm"
      >
        <Box padding="2" width="20%">
          Date
        </Box>
        <Box padding="2" width="10%">
          Passed
        </Box>
        <Box padding="2" width="30%">
          Test Case
        </Box>
        <Box padding="2" width="10%">
          Tester
        </Box>
        <Box padding="2" width="20%">
          Comments
        </Box>
        <Box padding="2" width="10%">
          ...
        </Box>
      </Flex>
      {filteredResults.map((r: any, i: number) => {
        return (
          <Flex direction="row" key={i}>
            <Box padding="2" width="20%">
              {new Date(r.createdAt).toLocaleString()}
            </Box>
            <Box padding="2" width="10%">
              {r.passed ? "Passed" : "Failed"}
            </Box>
            <Box padding="2" width="30%">
              {testCases?.cases?.find((x: any) => x.id === r.testCaseId)
                ?.name ?? ""}
              <Text>
                {Object.entries(r?.expectations ?? {}).map(
                  ([key, value]: any, i: number) => {
                    if (value) {
                      return <Icon as={BsCheckCircle} mr="1" />;
                    }
                    return <Icon as={BsCircle} mr="1" />;
                  }
                )}
              </Text>
            </Box>
            <Box padding="2" width="10%">
              {r.tester}
            </Box>
            <Box padding="2" width="20%">
              {r.comments}
            </Box>
            <Box padding="2" width="10%">
              <Menu>
                <MenuButton
                  bg="brand.bg"
                  border="1px"
                  borderColor="gray.100"
                  as={IconButton}
                  icon={<HamburgerIcon />}
                  _hover={{
                    bg: "brand.bg",
                  }}
                  _active={{
                    bg: "brand.bg",
                    transform: "scale(0.98)",
                    color: "brand.text.primary",
                  }}
                ></MenuButton>
                <MenuList bg="brand.bg">
                  <RBP admin collab>
                    <ConfirmButton
                      title="Confirm"
                      description="Are you sure you want to delete this result?"
                      cancel="Cancel"
                      affirmative="Confirm"
                      onConfirm={() =>
                        deleteResult(r.id, r.testCaseId, r.createdAt)
                      }
                    >
                      <OptionMenuItem>Delete</OptionMenuItem>
                    </ConfirmButton>
                  </RBP>
                </MenuList>
              </Menu>
            </Box>
          </Flex>
        );
      })}
    </Box>
  );
};

export const List = () => {
  const toast = useToast();
  const { results, del } = useResults();
  const { testCases } = useTestCase();
  const [searchParams] = useSearchParams();

  const [filteredResults, setFilteredResults] = useState([] as any[]);

  useEffect(() => {
    const definedFilter = searchParams.get("tc");
    if (definedFilter) {
      //
      setFilteredResults(
        results.filter((r: any) => {
          return r.testCaseId === definedFilter;
        })
      );
    } else {
      setFilteredResults(results);
    }
  }, [searchParams, results]);

  const deleteResult = (
    resultId: string,
    testCaseId: string,
    createdAt: string
  ) => {
    del(resultId, testCaseId, createdAt);
    toast({
      title: "Test result deleted",
    });
  };

  const props = {
    deleteResult,
    filteredResults,
    testCases,
  };

  return <ResultList {...props} />;
};

const OptionMenuItem = (props: any) => {
  return (
    <MenuItem
      bg="brand.bg"
      color="brand.text.primary"
      _focus={{
        bg: "brand.bg",
        color: "brand.primary.main",
      }}
      _hover={{
        bg: "brand.bg",
        color: "brand.primary.main",
      }}
      _active={{
        bg: "brand.bg",
        transform: "scale(0.98)",
        color: "brand.primary.main",
      }}
      {...props}
    ></MenuItem>
  );
};
