import * as React from "react";

import { Flex } from "@chakra-ui/react";

import { DesktopNav } from "./desktop";
export const SuiteNav = ({ items, role }: any) => {
  return (
    <Flex
      color="brand.light"
      borderBottom={1}
      borderStyle={"solid"}
      borderColor="brand.nav.second"
      align={"center"}
      height="40px"
    >
      <Flex flex={{ base: 1 }} justify={{ base: "center", md: "start" }}>
        <Flex display={{ base: "none", md: "flex" }}>
          <DesktopNav
            items={items}
            role={role}
            linkColor="brand.paper"
            linkHoverColor="#ff9d00"
          />
        </Flex>
      </Flex>
    </Flex>
  );
};
