// https://github.com/devias-io/material-kit-react/blob/main/src/theme/index.js
import { extendTheme } from "@chakra-ui/react";

export const theme = extendTheme({
  colors: {
    brand: {
      paper: "#FFFFFF",
      bg: "#F9FAFC",
      nav: {
        second: "#103757",
      },
      primary: {
        main: "#5048E5",
        light: "#828DF8",
        dark: "#3832A0",
        contrastText: "#FFFFFF",
      },
      secondary: {
        main: "#10B981",
        light: "#3FC79A",
        dark: "#0B815A",
        contrastText: "#FFFFFF",
      },
      success: {
        main: "#14B8A6",
        light: "#43C6B7",
        dark: "#0E8074",
        contrastText: "#FFFFFF",
      },
      info: {
        main: "#2196F3",
        light: "#64B6F7",
        dark: "#0B79D0",
        contrastText: "#FFFFFF",
      },
      warning: {
        main: "#FFB020",
        light: "#FFBF4C",
        dark: "#B27B16",
        contrastText: "#FFFFFF",
      },
      error: {
        main: "#D14343",
        light: "#DA6868",
        dark: "#922E2E",
        contrastText: "#FFFFFF",
      },
      text: {
        primary: "#121828",
        secondary: "#65748B",
        disabled: "rgba(55, 65, 81, 0.48)",
      },
    },
  },
  config: {
    cssVarPrefix: "ck",
  },
  styles: {
    global: {
      body: {
        bg: "#F9FAFC",
        color: "#121828",
      },
    },
  },

  components: {
    Form: {
      variants: {
        //https://chakra-ui.com/community/recipes/floating-labels
        floating: {
          container: {
            label: {
              right: 0,
              bottom: 0,
              zIndex: 2,
              position: "absolute",
              backgroundColor: "white",
              pointerEvents: "none",
              mx: 3,
              px: 1,
              my: 2,
              transformOrigin: "right bottom",
              transform: "scale(0.85) translateY(23px)",
            },
          },
        },
      },
    },
  },
});
