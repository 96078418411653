import {
  HStack,
  Input,
  VStack,
  Text,
  Box,
  useToast,
  useDisclosure,
} from "@chakra-ui/react";
import * as React from "react";
import { useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { useResults } from "../../hooks/useResults";
import { useTestCase } from "../../hooks/useTestCase";
import { PrimaryButton, WarningButton } from "../Button";
import { Card } from "../Card";
import { AddResultModal } from "../Results/AddModal";
import { ResultList } from "../Results/List";

export const TestCaseDetail = () => {
  const disclosure = useDisclosure();
  const { tcId } = useParams();
  const toast = useToast();
  const { testCases, updateCase, fetchCases, save } = useTestCase();
  const { results, del } = useResults();

  const tc = useMemo(() => {
    const found = testCases.cases.find((x: any) => x.id === tcId);
    if (!found) fetchCases();
    //console.log(tcId, found);
    return found;
  }, [tcId, testCases.cases, fetchCases]);

  const filteredResults = useMemo(() => {
    return results.filter((x: any) => x.testCaseId === tcId);
  }, [tcId, results]);

  const [expInput, setExpInput] = useState("");

  const deleteResult = (
    resultId: string,
    testCaseId: string,
    createdAt: string
  ) => {
    del(resultId, testCaseId, createdAt);
    toast({
      title: "Test result deleted",
    });
  };

  const addExp = () => {
    if (expInput === "") return;
    if (tc?.expectations?.find((x: any) => x.name === expInput)) return;

    updateCase(tc?.id, {
      _dirty: true,
      expectations: [
        ...(tc?.expectations ?? []),
        {
          name: expInput,
        },
      ],
    });

    setExpInput("");
  };

  const delExp = (name: string) => {
    updateCase(tc?.id, {
      _dirty: true,
      expectations: (tc?.expectations ?? []).filter(
        (e: any) => e.name !== name
      ),
    });
  };

  const tcNumber = tc
    ? tc.id.startsWith("tc_") && tc.id.replace("tc_", "")
    : "";

  return (
    <VStack>
      <Card width="100%" title="Test Case">
        <Text>
          {tcNumber}: {tc?.name}
        </Text>
      </Card>
      <Card width="100%" title="Expectations">
        <VStack alignItems="left">
          {tc?.expectations &&
            tc.expectations.map((e: any, i: number) => {
              return (
                <HStack>
                  <Box width="80%">
                    <Text key={i} width="80%">
                      {`> `}
                      {e.name}
                    </Text>
                  </Box>
                  <Box width="20%">
                    <WarningButton onClick={() => delExp(e.name)} size="xs">
                      Remove
                    </WarningButton>
                  </Box>
                </HStack>
              );
            })}
          <HStack>
            <Text width="20%">Add Expectation </Text>
            <Box width="60%">
              <Input
                value={expInput}
                onChange={(e: any) => setExpInput(e.target.value)}
              />
            </Box>
            <Box width="20%">
              <PrimaryButton onClick={addExp}>Add</PrimaryButton>
            </Box>
          </HStack>
        </VStack>
        <Box>
          <PrimaryButton onClick={() => save()}>Save</PrimaryButton>
        </Box>
      </Card>
      <Card
        width="100%"
        title="Results"
        primaryActionLabel="Add"
        primaryActionPosition="top"
        primaryActionClick={() => disclosure.onOpen()}
      >
        <ResultList
          showTc={false}
          testCases={{ cases: [tc] }}
          filteredResults={filteredResults}
          deleteResult={deleteResult}
        />
      </Card>

      {tc && <AddResultModal disclosure={disclosure} tcId={tc.id} />}
    </VStack>
  );
};
