import {
  HStack,
  Input,
  VStack,
  Text,
  Box,
  useToast,
  Heading,
  FormLabel,
  FormControl,
} from "@chakra-ui/react";
import * as React from "react";
import { useState } from "react";
import { Card } from "../Card";

export const TestCaseGenerator = () => {
  return (
    <VStack>
      <Heading as="h1" mt="4" color="brand.primary.main">
        Generate test cases from user stories
      </Heading>
      <Heading as="h3" mt="4">
        AI-Assisted
      </Heading>
      <Card width="100%" title="Your story">
        <FormControl>
          <FormLabel>As...</FormLabel>
          <Input type="text" placeholder="a new user" />
        </FormControl>
        <FormControl>
          <FormLabel>I want to...</FormLabel>
          <Input type="text" placeholder="sign up for your app" />
        </FormControl>
        <FormControl>
          <FormLabel>So...</FormLabel>
          <Input
            type="text"
            placeholder="I can have a personalized experience"
          />
        </FormControl>
        <Heading fontSize="sm" mt="2" as="h5">
          Acceptance criteria:
        </Heading>
        <Input type="text" placeholder="AC1" />
      </Card>
      <Card width="100%" title="Results">
        bye
      </Card>
    </VStack>
  );
};
