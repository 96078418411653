import { useQuery, useMutation } from "react-query";
import { useState, useEffect, useCallback } from "react";

import { list, create, del as _del } from "../api/results";
import { useParams } from "react-router-dom";

type AddResultProps = {
  comments: string;
  tester: string;
  passed: boolean;
  testCaseId: string;
  suiteId: string;
  expectations: any;
};

type TestCaseResultCache = {
  [tcId: string]: {
    date: number;
    passed: boolean;
    resultId: string;
  };
};

export const useResults = () => {
  const { suiteId = "" } = useParams();
  const [tcLatestResult, setTcLatestResult] = useState(
    {} as TestCaseResultCache
  );
  const { data, refetch } = useQuery(
    ["results", suiteId],
    () => suiteId && list({ suiteId }),
    {
      placeholderData: [],
      onSuccess: (response) => {
        const out = response.reduce((acc: TestCaseResultCache, r: any) => {
          const thisDate = new Date(r.createdAt).valueOf();

          const existing = acc[r.testCaseId];

          if (!existing || existing.date < thisDate) {
            acc[r.testCaseId] = {
              date: thisDate,
              passed: r.passed,
              resultId: r.id,
            };
          }

          return acc;
        }, tcLatestResult);

        setTcLatestResult(out);
      },
    }
  );

  useEffect(() => {
    refetch();
  }, []);

  const createApi = useMutation(create, {
    onSuccess: () => {
      refetch();
    },
  });

  const addResult = (data: AddResultProps) => {
    return createApi.mutateAsync(data);
  };

  const delApi = useMutation(_del, {
    onSuccess: () => {
      refetch();
    },
  });

  const del = (resultId: string, testCaseId: string, createdAt: string) => {
    delApi.mutate({
      id: resultId,
      suiteId,
      testCaseId,
      ts: new Date(createdAt).valueOf(),
    });
  };

  const getLatestResult = useCallback(
    (tcId: string) => {
      return tcLatestResult[tcId];
    },
    [tcLatestResult]
  );

  return {
    results: data,
    addResult,
    tcLatestResult,
    refetch,
    del,
    getLatestResult,
  };
};
