import axios from "axios";
import { remote } from "./config";

type CreateInput = {
  suiteId: string;
  name: string;
};

type UpdateInput = {
  suiteId: string;
  name: string;
  id: string;
};

type GetInput = {
  id: string;
};

type DelInput = {
  suiteId: string;
  id: string;
};

type ListInput = {
  suiteId: string;
};

export const list = async (data: ListInput) => {
  if (!data.suiteId) return [];

  const response = await axios.get(
    `${remote}/api/suite/${data.suiteId}/feat`,
    {}
  );

  return response.data.v;
};

export const get = async (data: GetInput) => {
  const response = await axios.get(`${remote}/api/suite/${data.id}/feat`, {});

  return response.data.v;
};

export const create = async (data: CreateInput) => {
  const url = `${remote}/api/suite/${data.suiteId}/feat`;
  const response = await axios.put(url, data, {});

  return response.data.v;
};

export const update = async (data: UpdateInput) => {
  const url = `${remote}/api/suite/${data.suiteId}/feat/${data.id}`;
  const response = await axios.post(url, data, {});
  return response.data.v;
};

export const del = async (data: DelInput) => {
  const url = `${remote}/api/suite/${data.suiteId}/feat/${data.id}`;
  const response = await axios.delete(url);
  return response.data.v;
};
