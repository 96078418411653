import axios from "axios";
import { remote } from "./config";

type CreateInput = {
  suiteId: string;
  name: string;
};

type UpdateInput = {
  name: string;
  suiteId: string;
  id: string;
  testCases: any[];
};

type GetInput = {
  id: string;
};

type DelInput = {
  suiteId: string;
  id: string;
};

type ListInput = {
  suiteId: string;
};

export const list = async (data: ListInput) => {
  const response = await axios.get(
    `${remote}/api/suite/${data.suiteId}/plan`,
    {}
  );

  return response.data.v;
};

export const get = async (data: GetInput) => {
  const response = await axios.get(`${remote}/api/suite/${data.id}/plan`, {});

  return response.data.v;
};

export const create = async (data: CreateInput) => {
  const url = `${remote}/api/suite/${data.suiteId}/plan`;
  const response = await axios.put(url, data, {});

  return response.data.v;
};

export const update = async (data: UpdateInput) => {
  const url = `${remote}/api/suite/${data.suiteId}/plan/${data.id}`;
  const response = await axios.post(url, data);
  return response.data.v;
};

export const del = async (data: DelInput) => {
  const url = `${remote}/api/suite/${data.suiteId}/plan/${data.id}`;
  const response = await axios.delete(url);
  return response.data.v;
};
