import * as React from "react";
import { useState } from "react";
import {
  FormControl,
  FormLabel,
  Text,
  Input,
  Button,
  Container,
  Grid,
  GridItem,
  Link,
} from "@chakra-ui/react";
import { useMutation, useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";

import { signin } from "../../api/signin";

export const SignIn = () => {
  const { mutate } = useMutation("signin", signin);
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const submit = () => {
    mutate(
      { username, password },
      {
        onSuccess: () => {
          queryClient.invalidateQueries("user");
          navigate("/app/dashboard");
        },
      }
    );
  };

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  return (
    <Container>
      <Grid templateColumns="repeat(3, 1fr)" gap={2} mt={3}>
        <GridItem colSpan={3}>
          <Text fontSize="4xl">Sign In</Text>
        </GridItem>
        <GridItem colSpan={3}>
          <FormControl>
            <FormLabel>
              Email
              <Input
                type="email"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
              />
            </FormLabel>
            <FormLabel>
              Password
              <Input
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </FormLabel>

            <Button
              onClick={submit}
              bg="brand.primary"
              color="brand.light"
              type="submit"
            >
              Sign In
            </Button>
            <Link href="/forgotten" ml="2">
              Forgotten Password
            </Link>
          </FormControl>
        </GridItem>
      </Grid>
    </Container>
  );
};
