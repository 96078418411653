import * as React from "react";
import { Button, ButtonProps } from "@chakra-ui/react";

export const primaryBtnStyle = {
  bg: "brand.primary.main",
  color: "gray.50",
  _hover: {
    bg: "brand.primary.main",
    filter: "brightness(1.1)",
  },
  _active: {
    bg: "brand.primary.main",
    transform: "scale(0.98)",
    color: "white",
  },
};

export const infoBtnStyle = {
  bg: "brand.info.main",
  color: "gray.50",
  _hover: {
    bg: "brand.info.main",
    filter: "brightness(1.1)",
  },
  _active: {
    bg: "brand.info.main",
    transform: "scale(0.98)",
    color: "white",
  },
};

export const warningBtnStyle = {
  bg: "brand.warning.main",
  color: "gray.50",
  _hover: {
    bg: "brand.warning.main",
    filter: "brightness(1.1)",
  },
  _active: {
    bg: "brand.warning.main",
    transform: "scale(0.98)",
    color: "white",
  },
};

export const mutedBtnStyle = {
  bg: "gray.50",
  color: "gray.400",
  _hover: {
    bg: "gray.50",
    filter: "brightness(1.1)",
  },
  _active: {
    bg: "gray.50",
    transform: "scale(0.98)",
    color: "white",
  },
};

export const PrimaryButton = (props: ButtonProps) => {
  return <Button {...primaryBtnStyle} {...props}></Button>;
};

export const InfoButton = (props: ButtonProps) => {
  return <Button {...infoBtnStyle} {...props}></Button>;
};

export const WarningButton = (props: ButtonProps) => {
  return <Button {...warningBtnStyle} {...props}></Button>;
};

export const MutedButton = (props: ButtonProps) => {
  return <Button {...mutedBtnStyle} {...props}></Button>;
};
