import * as React from "react";
import {
  Button,
  Box,
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogBody,
  AlertDialogFooter,
  useDisclosure,
} from "@chakra-ui/react";

type ConfirmButtonProps = {
  children: JSX.Element;
  title: string;
  description: string;
  affirmative: string;
  cancel: string;
  onConfirm: () => void;
};

export const ConfirmButton = ({
  children,
  title,
  description,
  cancel,
  affirmative,
  onConfirm,
}: ConfirmButtonProps) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const cancelRef = React.useRef();

  const confirm = () => {
    onClose();
    onConfirm();
  };

  return (
    <>
      <Box onClick={onOpen}>{children}</Box>
      <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        onClose={onClose}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              {title}
            </AlertDialogHeader>

            <AlertDialogBody>{description}</AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={onClose}>
                {cancel}
              </Button>
              <Button colorScheme="red" onClick={confirm} ml={3}>
                {affirmative}
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  );
};
