import * as React from "react";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

export const SuiteDefaultRedirect = () => {
  const navigate = useNavigate();
  const { suiteId = "" } = useParams();

  useEffect(() => {
    if (suiteId.length > 0) {
      console.log("Navigating to default page");
      navigate(`/app/${suiteId}/tc`);
    } else {
      console.log("No suiteId found", suiteId);
    }
  }, [suiteId]);

  return <>Redirecting</>;
};
