import axios from "axios";
import { remote } from "./config";
import { TestCase } from "../app/testSuite";

type CreateInput = {
  suiteId: string;
} & TestCase;

type UpdateInput = TestCase & {
  suiteId: string;
};

type GetInput = {
  id: string;
};

type RmInput = {
  id: string;
  suiteId: string;
};

type ListInput = {
  suiteId: string;
};

export const list = async (data: ListInput) => {
  if (!data.suiteId) return [];

  const response = await axios.get(
    `${remote}/api/suite/${data.suiteId}/tc`,
    {}
  );

  return response.data.v;
};

export const get = async (data: GetInput) => {
  const response = await axios.get(`${remote}/api/suite/${data.id}`, {});

  return response.data.v;
};

export const create = async (data: CreateInput) => {
  const url = `${remote}/api/suite/${data.suiteId}/tc`;
  const response = await axios.put(url, data, {});

  return response.data.v;
};

export const update = async (data: UpdateInput) => {
  const url = `${remote}/api/suite/${data.suiteId}/tc/${data.id}`;
  const response = await axios.post(url, data, {});
  return response.data.v;
};

export const rmCase = async (data: RmInput) => {
  const url = `${remote}/api/suite/${data.suiteId}/tc/${data.id}`;
  const response = await axios.delete(url);
  return response.data.v;
};
