import axios from "axios";
import { remote } from "./config";

type CreateInput = {
  suiteId: string;
  passed: boolean;
  comments: string;
  tester: string;
  testCaseId: string;
};

type UpdateInput = {
  suiteId: string;
  name: string;
  id: string;
};

type GetInput = {
  id: string;
};

type DelInput = {
  suiteId: string;
  id: string;
  testCaseId: string;
  ts: number;
};

type ListInput = {
  suiteId: string;
};

export const list = async (data: ListInput) => {
  if (!data.suiteId) return [];

  const response = await axios.get(
    `${remote}/api/suite/${data.suiteId}/result`
  );

  return response.data.v;
};

export const get = async (data: GetInput) => {
  if (!data.id) return null;

  const response = await axios.get(`${remote}/api/suite/${data.id}/result`, {});

  return response.data.v;
};

export const create = async (data: CreateInput) => {
  if (!data.suiteId) return null;

  const url = `${remote}/api/suite/${data.suiteId}/result`;
  const response = await axios.put(url, data, {});

  return response.data.v;
};

export const update = async (data: UpdateInput) => {
  const url = `${remote}/api/suite/${data.suiteId}/result/${data.id}`;
  const response = await axios.post(url, data, {});
  return response.data.v;
};

export const del = async (data: DelInput) => {
  const url = `${remote}/api/suite/${data.suiteId}/result/${data.id}`;
  const response = await axios.delete(url, {
    data: {
      testCaseId: data.testCaseId,
      ts: data.ts,
    },
  });
  return response.data.v;
};
