export const useTracking = () => {
  const track = (eventName: string, data: any) => {
    //@ts-ignore
    if (!window.dataLayer) return;

    //@ts-ignore
    window.dataLayer.push({
      event: eventName,
      ...data,
    });
  };

  return {
    track,
  };
};
