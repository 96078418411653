import * as React from "react";
import { useMemo } from "react";
import { Outlet, useNavigate, useParams } from "react-router-dom";

import { Box, Grid, GridItem, Collapse, useDisclosure } from "@chakra-ui/react";

import { MobileNav } from "./mobile";
import { useLocalStorage } from "../../hooks/useLocalStorage";
import { suiteNav, topNav } from "./navItems";
import { useSuite } from "../../hooks/useSuite";
import { SuiteNav } from "./SuiteNav";
import { TopNav } from "./TopNav";

export const MainLayout = () => {
  const { isOpen, onToggle } = useDisclosure();
  const [storedValue] = useLocalStorage();
  const navigate = useNavigate();
  const { suiteId = "" } = useParams();
  const { suite } = useSuite();

  const suiteItems = useMemo(() => {
    const generated = suiteNav(storedValue.suites, storedValue.suiteNames);
    return generated;
  }, [storedValue, suiteNav]);

  const topItems = useMemo(() => {
    const generated = topNav(storedValue.suites, storedValue.suiteNames);
    return generated;
  }, [storedValue, topNav]);

  return (
    <Box>
      <Grid
        templateColumns="repeat(12, 1fr)"
        gap={2}
        pt={3}
        bg="white"
        borderBottom="brand.bg"
        boxShadow="0px 0px 2px 0px #888888"
      >
        <GridItem colSpan={2}></GridItem>
        <GridItem colSpan={8}>
          <TopNav
            isOpen={isOpen}
            onToggle={onToggle}
            items={topItems}
            navigate={navigate}
            suiteId={suite.suiteId}
            role={suite.role}
          />
          <Collapse in={isOpen} animateOpacity>
            <MobileNav items={[...topItems, ...suiteItems]} />
          </Collapse>
        </GridItem>
        <GridItem colSpan={2}></GridItem>
      </Grid>
      {suiteId.length > 0 && (
        <Grid
          templateColumns="repeat(12, 1fr)"
          gap={2}
          backgroundColor="brand.nav.second"
          borderBottom="brand.nav.second"
          boxShadow="0px 0px 2px 0px #888888"
        >
          <GridItem colSpan={2}></GridItem>
          <GridItem colSpan={8}>
            <SuiteNav
              isOpen={isOpen}
              onToggle={onToggle}
              items={suiteItems}
              navigate={navigate}
              suiteId={suite.suiteId}
              role={suite.role}
            />
          </GridItem>
          <GridItem colSpan={2}></GridItem>
        </Grid>
      )}
      <Grid templateColumns="repeat(12, 1fr)" gap={2} mt={3}>
        <GridItem colSpan={2}></GridItem>
        <GridItem colSpan={8}>
          <Outlet />
        </GridItem>
        <GridItem colSpan={2}></GridItem>
      </Grid>
    </Box>
  );
};
