//
import * as React from "react";
import { NavLink as Nav, useParams } from "react-router-dom";
import {
  Box,
  Flex,
  Text,
  Stack,
  Icon,
  Link,
  Popover,
  PopoverTrigger,
  PopoverContent,
  useColorModeValue,
} from "@chakra-ui/react";
import { ChevronRightIcon } from "@chakra-ui/icons";

const popoverContentBgColor = "brand.paper";

export const DesktopNav = ({ items, linkColor, linkHoverColor }: any) => {
  const params = useParams();

  return (
    <Stack direction={"row"} spacing={4}>
      {items.map((navItem: any) => (
        <Box key={navItem.label}>
          {navItem.children ? (
            <Popover trigger={"hover"} placement={"bottom-start"}>
              <PopoverTrigger>
                <Link
                  as={Nav}
                  to={navItem.href?.(params) ?? ""}
                  p={2}
                  fontSize={"sm"}
                  fontWeight={600}
                  color={linkColor}
                  _hover={{
                    textDecoration: "none",
                    color: linkHoverColor,
                  }}
                  target={navItem.newTab ? "_blank" : "_self"}
                  rel="canonical"
                >
                  {navItem.label}
                </Link>
              </PopoverTrigger>

              <PopoverContent
                border={0}
                boxShadow={"xl"}
                bg={popoverContentBgColor}
                p={4}
                rounded={"xl"}
                minW={"sm"}
              >
                <Stack>
                  {navItem.children.map((child: any) => {
                    return (
                      <DesktopSubNav
                        key={child.label}
                        {...child}
                        params={params}
                      />
                    );
                  })}
                </Stack>
              </PopoverContent>
            </Popover>
          ) : (
            <Link
              _activeLink={{ fontWeight: "bold" }}
              as={Nav}
              to={navItem.href?.(params) ?? ""}
              p={2}
              fontSize={"sm"}
              fontWeight={600}
              color={linkColor}
              _hover={{
                textDecoration: "none",
                color: linkHoverColor,
              }}
              target={navItem.newTab ? "_blank" : "_self"}
            >
              {navItem.label}
            </Link>
          )}
        </Box>
      ))}
    </Stack>
  );
};

const DesktopSubNav = ({ label, href, subLabel, params }: any) => {
  return (
    <Link
      as={Nav}
      to={href?.(params) ?? ""}
      role={"group"}
      display={"block"}
      p={2}
      rounded={"md"}
      _hover={{ bg: useColorModeValue("pink.50", "gray.900") }}
    >
      <Stack direction={"row"} align={"center"}>
        <Box>
          <Text
            transition={"all .3s ease"}
            _groupHover={{ color: "pink.400" }}
            fontWeight={500}
          >
            {label}
          </Text>
          <Text fontSize={"sm"}>{subLabel}</Text>
        </Box>
        <Flex
          transition={"all .3s ease"}
          transform={"translateX(-10px)"}
          opacity={0}
          _groupHover={{ opacity: "100%", transform: "translateX(0)" }}
          justify={"flex-end"}
          align={"center"}
          flex={1}
        >
          <Icon color={"pink.400"} w={5} h={5} as={ChevronRightIcon} />
        </Flex>
      </Stack>
    </Link>
  );
};
