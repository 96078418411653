import * as React from "react";
import * as ReactDOM from "react-dom/client";

import { ChakraProvider } from "@chakra-ui/react";
import { theme } from "./theme";
import { App } from "./components/App";

const Main: React.FC = () => {
  return (
    <ChakraProvider theme={theme}>
      <App />
    </ChakraProvider>
  );
};

//@ts-ignore
const root = ReactDOM.createRoot(document.getElementById("main"));
root.render(<Main />);
