import * as React from "react";
import { Heading, VStack, Text, Box, Link } from "@chakra-ui/react";
import { Card } from "../Card";
import { CheckIcon } from "@chakra-ui/icons";

export const Pricing = () => {
  return (
    <VStack alignItems="left" mb="5">
      <Heading fontSize="xl" mt="2" mb="2">
        Pricing
      </Heading>
      <Card
        title="Generous Free Tier"
        width="100%"
        titleColor="brand.primary.main"
        padding="9"
        mt="0"
      >
        <Heading fontSize="sm" mt="3">
          Why
        </Heading>
        <Text mt="3">
          Larger organizations with QA/testing teams will have enterprise
          software that serve them. But they're not the only teams that need to
          test software. There are many administrative teams that maintain
          software from vendors e.g. SAP, ServiceNow, SalesForce that don't have
          access to best-practice test planning.
        </Text>{" "}
        <Text>
          Testing domain knowledge is often lost in people's heads, in
          spreadsheets or emails.
        </Text>{" "}
        <Text>
          Needing budget approval shouldn't be the limiting factor to having
          rigour in your quality-assurance practices.
        </Text>
        <Heading fontSize="sm" mt="5">
          Features
        </Heading>
        <VStack alignItems="left" mt="3">
          <Text>
            <CheckIcon mr="2" color="brand.success.main" />
            No time limits
          </Text>
          <Text>
            <CheckIcon mr="2" color="brand.success.main" />
            Generate as many test suites as you want
          </Text>
          <Text>
            <CheckIcon mr="2" color="brand.success.main" />
            Record results against test cases
          </Text>
          <Text>
            <CheckIcon mr="2" color="brand.success.main" />
            Assign test cases to test plans e.g. smoke test, regression test,
            vendor-upgrades
          </Text>

          <Text>
            <CheckIcon mr="2" color="brand.success.main" />
            Test suites are protected by public but hard-to-guess links
          </Text>
          <Text>
            <CheckIcon mr="2" color="brand.success.main" />
            Role-based access by generated, revokable links
          </Text>
        </VStack>
      </Card>
      <Card
        title="Premium"
        width="100%"
        titleColor="brand.primary.main"
        padding="9"
        mt="5"
      >
        <Text>The Premium tier is still in progress!</Text>
        <Heading fontSize="sm" mt="5">
          Roadmap
        </Heading>

        <VStack alignItems="left" mt="3">
          <Text>
            <CheckIcon mr="2" color="brand.success.main" />
            Upload images as test case assets
          </Text>
          <Text>
            <CheckIcon mr="2" color="brand.success.main" />
            Upload images as result artifacts
          </Text>
          <Text>
            <CheckIcon mr="2" color="brand.success.main" />
            Record results against test cases
          </Text>
          <Text>
            <CheckIcon mr="2" color="brand.success.main" />
            Secure links with user accounts
          </Text>
          <Text>
            <CheckIcon mr="2" color="brand.success.main" />
            Connect to Github/Jira issues
          </Text>
        </VStack>
      </Card>
      <Card
        title="Feature Request"
        width="100%"
        titleColor="brand.primary.main"
        padding="9"
        mt="5"
      >
        <Text>
          Let us know your needs!
          <Link href="mailto:support@testerplan.com" ml="2">
            support@testerplan.com
          </Link>
        </Text>
        <Text>
          Features you request won't automatically go into the Premium tier.
        </Text>
      </Card>
    </VStack>
  );
};
