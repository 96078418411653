//
export interface NavItem {
  label: string;
  subLabel?: string;
  children?: Array<NavItem>;
  href?: (x: any) => string;
  newTab?: boolean;
  roles?: string[];
}

export const topNav = (): Array<NavItem> => {
  return [
    {
      label: "Suites",
      href: () => `/`,
    },
    {
      label: "Learn",
      href: () => `/learn/index.html`,
      newTab: true,
    },
    {
      label: "Premium",
      href: () => `/app/premium`,
    },
  ];
};

export const suiteNav = (): Array<NavItem> => {
  return [
    {
      label: "Test Cases",
      href: ({ suiteId }) => `/app/${suiteId}/tc`,
    },
    {
      label: "Features",
      href: ({ suiteId }) => `/app/${suiteId}/feat`,
    },
    {
      label: "Plans",
      href: ({ suiteId }) => `/app/${suiteId}/plan`,
    },
    {
      label: "Results",
      href: () => `?`,
      children: [
        {
          label: "View",
          href: ({ suiteId }) => `/app/${suiteId}/results`,
        },
        {
          label: "Record",
          roles: ["admin", "collab", "executor"],
          href: ({ suiteId }) => `/app/${suiteId}/results/add`,
        },
      ],
    },
    {
      label: "Settings",
      href: ({ suiteId }) => `/app/${suiteId}/admin`,
    },
  ];
};
