import * as React from "react";
import { useState } from "react";
import {
  Text,
  Modal,
  ModalHeader,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalFooter,
  Button,
  InputGroup,
  Input,
  InputRightElement,
} from "@chakra-ui/react";
import { mutedBtnStyle, primaryBtnStyle } from "../Button";
import { useBoolean, useCopyToClipboard } from "usehooks-ts";
import { useSearchParams } from "react-router-dom";

const makeLink = (suiteId: string) =>
  `${window.location.protocol}//${window.location.host}/app/${suiteId}`;

export const NewSuiteModal = ({ disclosure, suiteId }: any) => {
  const { isOpen, onClose } = disclosure;
  const [, setSearchParams] = useSearchParams();
  const copyClicked = useBoolean(false);
  const [, copy] = useCopyToClipboard();

  const clickCopy = () => {
    copyClicked.setTrue();
    copy(makeLink(suiteId));
  };

  const close = () => {
    setSearchParams();
    onClose();
  };

  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent maxWidth="40%">
          <ModalHeader>New Test Suite Created</ModalHeader>
          <ModalBody>
            No sign in required BUT you'll need to save this link!
            <InputGroup size="sm">
              <Input pr="4.5rem" value={`${makeLink(suiteId)}`} size="sm" />
              <InputRightElement width="4.5rem">
                <Button
                  onClick={clickCopy}
                  size="sm"
                  h="1.75rem"
                  {...(copyClicked.value ? mutedBtnStyle : primaryBtnStyle)}
                >
                  Copy
                </Button>
              </InputRightElement>
            </InputGroup>
          </ModalBody>
          <ModalFooter>
            <Button
              {...(copyClicked.value ? primaryBtnStyle : mutedBtnStyle)}
              onClick={close}
            >
              I've saved the link
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};
