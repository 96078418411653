import * as React from "react";
import { Stack, Text, Button, Box, Flex } from "@chakra-ui/react";

//https://chakra-templates.dev/components/cards

type CardProps = {
  title: string;
  width: any;
  children: any;
  primaryActionLabel?: string;
  primaryActionClick?: () => void;
  primaryActionPosition?: "top" | "bottom";
  badge?: any;
  titleColor?: string;
  padding?: string;
  mt?: string;
};

export const Card = ({
  title,
  children,
  primaryActionClick,
  primaryActionLabel,
  primaryActionPosition,
  badge,
  width,
  titleColor = "gray.400",
  padding = "5",
  mt = "0",
}: CardProps) => {
  return (
    <Stack
      p={padding}
      boxShadow="lg"
      mb="4"
      mr="4"
      ml="4"
      mt={mt}
      borderRadius="sm"
      bg="brand.secondary"
      width={width}
    >
      <Flex direction="row" mt="1">
        {badge}
        <Text ml={badge ? "2" : "0"} fontWeight="semibold" color={titleColor}>
          {title}
        </Text>
        {primaryActionPosition === "top" && (
          <Button
            colorScheme="green"
            onClick={primaryActionClick}
            ml={2}
            size="xs"
          >
            {primaryActionLabel}
          </Button>
        )}
      </Flex>
      <Box>{children}</Box>
      {primaryActionPosition === "bottom" && (
        <Stack
          direction={{ base: "column", md: "row" }}
          justifyContent="space-between"
        >
          {primaryActionLabel && (
            <Stack direction={{ base: "column", md: "row" }}>
              <Button colorScheme="green" onClick={primaryActionClick}>
                {primaryActionLabel}
              </Button>
            </Stack>
          )}
        </Stack>
      )}
    </Stack>
  );
};
